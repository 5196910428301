import './Subscriptions.css'; 
import React, { useState, useEffect } from 'react';
import arrow from '../assets/arrow.svg';
import { FaCheck } from 'react-icons/fa';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuth0 } from "@auth0/auth0-react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate,  useLocation } from 'react-router-dom';
import cross from '../assets/cross.svg';

import {
    upgradeSubscription,
    cancelSubscription,
    verifyPayment,
    fetchPlanDataAPI
  } from './ApiUtils'; 



const Subscriptions = () => {
    const location = useLocation(); // Use useLocation hook
    const navigate = useNavigate();
const [selectedPlans, setSelectedPlans] = useState({ product1: '', product2: '' });
const [currentPlan, setCurrentPlan] = useState({ product1: '', product2: '', amount: 0 });
    
const [showNewPlan, setShowNewPlan] = useState(false);
const [totalAmount, setTotalAmount] = useState(0);
const [loading, setLoading] = useState(false);
const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

const handleBackClick = () => {
    navigate('/'); 
  };

const product1Prices = {
    Free: 0,
    Basic: 50,
    Business: 100,
  };
  
  const product2Prices = {
    Free: 0,
    Basic: 25,
    Business: 40,
  };

  const [disabledFeatures, setDisabledFeatures] = useState({
    product1: [], 
    product2: []
});

const handleUpgrade = async () => {
    setLoading(true);
    try {
      const data = await upgradeSubscription({
        getAccessTokenSilently,
        getIdTokenClaims,
        selectedPlans,
      });

      // If the API responds with a checkout_url, redirect
      if (data.checkout_url) {
        window.location.href = data.checkout_url;
      }
    } catch (error) {
      console.error("Error upgrading subscription:", error);
      toast.error("Failed to upgrade subscription. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  
  const handleCancel = async () => {
    setLoading(true);
    try {
      await cancelSubscription({ getAccessTokenSilently, getIdTokenClaims });

      // Reset local states
      setCurrentPlan({
        product1: 'Free',
        product2: 'Free',
        amount: 0,
      });
      setSelectedPlans({
        product1: 'Free',
        product2: 'Free',
      });
      setShowNewPlan(false);
      setTotalAmount(0);

      toast.info("Your subscription has been canceled successfully.", {
        position: 'top-center',
        autoClose: 3000,
        style: { width: '500px' }
      });
    } catch (error) {
      console.error("Error canceling subscription:", error);
      alert("Failed to cancel the subscription. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePlanChange = (product, plan) => {
    const newSelection = { ...selectedPlans, [product]: plan };
    setSelectedPlans(newSelection);

    // Determine disabled features based on selected plans
    const newDisabledFeatures = { product1: [], product2: [] };
    if (newSelection.product1 === 'Free') {
      newDisabledFeatures.product1 = [
        'Historical Context', 
        'Custom Branding', 
        '5M+ respondents', 
        '150 countries', 
        'Smart screening', 
        'Chat support', 
        'Phone support'
      ];
    }
    if (newSelection.product2 === 'Free') {
      newDisabledFeatures.product2 = [
        'Chat support', 
        'Phone support'
      ];
    }
    setDisabledFeatures(newDisabledFeatures);

    const total = 
      product1Prices[newSelection.product1] +
      product2Prices[newSelection.product2];

    setTotalAmount(total);
    setShowNewPlan(total !== currentPlan.amount && total > 0);
  };

// Subscriptions.js

useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');
  
    if (sessionId) {
      verifyPayment(sessionId);
      queryParams.delete('session_id');
    }
  }, [location.search]);
  
  const verifyPaymentSession = async (sessionId) => {
    try {
      const data = await verifyPayment({
        sessionId,
        getAccessTokenSilently,
        getIdTokenClaims
      });

      if (data.status === 'success') {
        toast.success('Payment successful! Your subscription has been updated.', {
          position: 'top-center',
          autoClose: 3000,
          style: { width: '500px' }
        });
      } else {
        toast.error('Payment was not successful. Please try again.', {
          position: 'top-center',
          autoClose: 3000,
          style: { width: '500px' }
        });
      }
    } catch (error) {
      console.error('Error verifying payment:', error);
      toast.error('An error occurred while verifying payment.', {
        position: 'top-center',
        autoClose: 3000
      });
    } finally {
      // Clean up the URL by removing session_id
      navigate('/subscriptions', { replace: true });
    }
  };

  
  useEffect(() => {
    const loadPlanData = async () => {
      setLoading(true);
      try {
        const data = await fetchPlanDataAPI({
          getAccessTokenSilently,
          getIdTokenClaims,
        });

        // Calculate and set current plan details
        setCurrentPlan({
          product1: data.plan_name['Echo AI Interviews'],
          product2: data.plan_name['Transcription & Analysis'],
          amount: data.interview_plan_price + data.transcription_plan_price
        });

        // Preselect radio buttons based on the fetched plan names
        setSelectedPlans({
          product1: data.plan_name['Echo AI Interviews'],
          product2: data.plan_name['Transcription & Analysis'],
        });
      } catch (error) {
        console.error('Error fetching plan data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    loadPlanData();
  }, [getAccessTokenSilently, getIdTokenClaims]);


  if (loading ) {
    return <div className="loading-indicator-container">
              <div className="loading-indicator"></div>
            </div>;
  }
  

  return (

    <>
    <ToastContainer />

      <div className='subscriptions-body'>


      <div className="builder-header">
            <div className="back-button-campaign-builder" onClick={handleBackClick}>
             <img src={cross} className='cross-icon'/>
            </div>

            <div className='campaign-name-input'>Manage your subscription</div>

          </div>

              <div className='current-plan-container'>
                  <div className='pricing-text-content'>
                      <div className='pricing-text-title'>Simple, transparent pricing</div>
                      <div className='pricing-text-description'>Plans built for creators and businesses of all sizes.</div>
                      <img className='arrow' src={arrow}></img>
                  </div>

                  <div className='current-plan-section'>
                        <div className='current-plan-title'>Current Plan</div>
                        <div className='current-plan-amount'>${currentPlan.amount}/user/mo</div>
                        <div className='current-plan-product'>Echo AI Interviews: {currentPlan.product1}</div>
                        <div className='current-plan-product'>Transcription & Analysis: {currentPlan.product2}</div>
                        {currentPlan.amount === 0 ? null : <button className='cancel-button' onClick={handleCancel}>
                                     Cancel
                        </button>}

                        </div>

                  <div className={`new-plan-section ${showNewPlan ? 'show' : ''}`}>
                      <div className='new-plan-title'> New Plan</div>
                      <div className='new-plan-amount'>{`$${totalAmount}/user/mo`}</div>
                      <div className='new-plan-product'> Echo AI Interviews: <span className='product-name'>{selectedPlans.product1}</span></div>
                      <div className='new-plan-product'>  Transcription & Analysis: <span className='product-name'>{selectedPlans.product2}</span></div>
                      <button className='upgrade-button-subscriptions' onClick={handleUpgrade}>
                        {totalAmount > currentPlan.amount ? 'Upgrade' : 'Change Plan'}
                        </button>


                  </div>
              </div>


              <div className='choose-product-plan-title'>Choose a plan from the two products below that works best for you</div>

              <div className='product-plan-container'>
                  <div className='product-plan-detail-section'>
                      <div className='product-title'>Echo AI Interviews</div>
                      {/* <div className='product-description'>Conduct end to end interviews using Echo AI.</div> */}
                      <div className="plan-radio-row">
                          <div className='plan-radio-row-item'>
                              {/* <div className='plan-helper-hint'>Best for small teams</div> */}
                              <label className='plan-radio-row-item'>
                                  <div className='plan-item-content'>
                                      <div className='title-row'>

                                          <span className='plan-title'>Free</span>
                                          <input
                                              className='radio-button'
                                              type="radio"
                                              name="radio-product-1"
                                              value="Free"
                                              checked={selectedPlans.product1 === 'Free'}
                                              onChange={() => handlePlanChange('product1', 'Free')} />
                                      </div>

                                      <div className='plan-amount-row'>
                                          <div className='plan-amount-dollar'>$0</div>
                                          <div className='plan-amount-text'>/user/mo</div>
                                      </div>
                                      <div className='usage-row'>50 Interview mins</div>
                                      <div className='overage-row'>Overage at 0.011$ per minute</div>
                                  </div>
                              </label>
                          </div>
                          <label className='plan-radio-row-item'>
                              {/* <div className='plan-helper-hint'>Best for small teams</div> */}
                              <div className='plan-item-content'>
                                  <div className='title-row'>
                                      <span className='plan-title'>Basic</span>
                                      <input
                                          className='radio-button'
                                          type="radio"
                                          id="basicPlan"
                                          name="radio-product-1"
                                          value="Basic"
                                          checked={selectedPlans.product1 === 'Basic'}
                                          onChange={() => handlePlanChange('product1', 'Basic')} />

                                  </div>
                                  <div className='plan-amount-row'>
                                      <div className='plan-amount-dollar'>$50</div>
                                      <div className='plan-amount-text'>/user/mo</div>
                                  </div>
                                  <div className='usage-row'>100 Interview mins</div>
                                  <div className='overage-row'>Overage at 0.011$ per minute</div>
                              </div>

                          </label>
                          <label className='plan-radio-row-item'>
                              {/* <div className='plan-helper-hint'>Best for large teams</div> */}
                              <div className='plan-item-content'>
                                  <div className='title-row'>
                                      <span className='plan-title'> Business</span>
                                      <input
                                          className='radio-button'
                                          type="radio"
                                          id="businessPlan"
                                          name="radio-product-1"
                                          value="Business"
                                          checked={selectedPlans.product1 === 'Business'}
                                          onChange={() => handlePlanChange('product1', 'Business')} />

                                  </div>
                                  <div className='plan-amount-row'>
                                      <div className='plan-amount-dollar'>$100</div>
                                      <div className='plan-amount-text'>/user/mo</div>
                                  </div>
                                  <div className='usage-row'>200 Interview mins</div>
                                  <div className='overage-row'>Overage at 0.011$ per minute</div>
                              </div>

                          </label>

                      </div>

                      {/* <div className='features-title'> Features</div> */}
                      <div className="features-hr-top"></div>
                      <div className='feature-list'>
                          <div className='feature-item-row-header-row'>
                              {/* <div className='feature-item-icon'><FaCheck/></div> */}
                              <div className='feature-item-title'>End to End AI interviews</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product1.includes('Consumer research') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Consumer research</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product1.includes('Customer Feedback<') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Customer Feedback</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product1.includes('AD testing') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'> AD testing</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product1.includes('Concept testing') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Concept testing</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product1.includes('Usability testing') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Usability testing</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product1.includes('Historical Context') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Historical Context</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product1.includes('Custom Branding') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Custom Branding</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row-header-row'>
                              <div className='feature-item-title'>Recruitment Panel</div>
                          </div>
                          <div className="features-hr"></div>

                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product1.includes('5M+ respondents') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>5M+ respondents</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product1.includes('150 countries') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>150 countries</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product1.includes('Smart screening') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Smart screening</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row-header-row'>

                              <div className='feature-item-title'>Analysis and insights</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product1.includes('Affinity Mapping') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Affinity Mapping</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product1.includes('Summary and key insights') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Summary and key insights</div>
                          </div>

                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product1.includes('Question level analysis') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Question level analysis</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product1.includes('Audio recording playback') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Audio recording playback</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row-header-row'>

                              <div className='feature-item-title'>Support</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product1.includes('Email support') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Email support</div>
                          </div>

                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product1.includes('Chat support') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Chat support</div>
                          </div>

                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product1.includes('Phone support') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Phone support</div>
                          </div>
                      </div>
                  </div>

                  <div className='product-plan-detail-section'>
                      <div className='product-title'>Transcription and Analysis</div>
                      <div className="plan-radio-row">

                          <label className='plan-radio-row-item'>
                              <div className='plan-item-content'>
                                  <div className='title-row'>
                                      <span className='plan-title'>Free</span>
                                      <input
                                          className='radio-button'
                                          type="radio"
                                          id="freePlan"
                                          name="radio-product-2"
                                          value="Free"
                                          checked={selectedPlans.product2 === 'Free'}
                                          onChange={() => handlePlanChange('product2', 'Free')} />

                                  </div>
                                  <div className='plan-amount-row'>
                                      <div className='plan-amount-dollar'>$0</div>
                                      <div className='plan-amount-text'>/user/mo</div>
                                  </div>
                                  <div className='usage-row'>50 Interview mins</div>
                                  <div className='overage-row'>Overage at 0.011$ per minute</div>
                              </div>


                          </label>

                          <label className='plan-radio-row-item'>
                              {/* <div className='plan-helper-hint'>Best for small teams</div> */}
                              <div className='plan-item-content'>
                                  <div className='title-row'>
                                      <span className='plan-title'>Basic</span>
                                      <input
                                          className='radio-button'
                                          type="radio"
                                          id="basicPlan"
                                          name="radio-product-2"
                                          value="Basic"
                                          checked={selectedPlans.product2 === 'Basic'}
                                          onChange={() => handlePlanChange('product2', 'Basic')} />

                                  </div>
                                  <div className='plan-amount-row'>
                                      <div className='plan-amount-dollar'>$25</div>
                                      <div className='plan-amount-text'>/user/mo</div>
                                  </div>
                                  <div className='usage-row'>500 Interview mins</div>
                                  <div className='overage-row'>Overage at 0.011$ per minute</div>
                              </div>
                          </label>

                          <label className='plan-radio-row-item'>
                              {/* <div className='plan-helper-hint'>Best for large teams</div> */}
                              <div className='plan-item-content'>
                                  <div className='title-row'>
                                      Business
                                      <input
                                          className='radio-button'
                                          type="radio"
                                          id="businessPlan"
                                          name="radio-product-2"
                                          value="Business"
                                          checked={selectedPlans.product2 === 'Business'}
                                          onChange={() => handlePlanChange('product2', 'Business')} />


                                  </div>
                                  <div className='plan-amount-row'>
                                      <div className='plan-amount-dollar'>$40</div>
                                      <div className='plan-amount-text'>/user/mo</div>
                                  </div>
                                  <div className='usage-row'>2000 Transcription mins</div>
                                  <div className='overage-row'>Overage at 0.011$ per minute</div>
                              </div>
                          </label>

                      </div>

                      <div className="features-hr-top"></div>
                      <div className='feature-list'>
                          <div className='feature-item-row-header-row'>
                              {/* <div className='feature-item-icon'>
{disabledFeatures.product1.includes('Feature Name') || disabledFeatures.product2.includes('Feature Name') ? '-' : <FaCheck />}
</div>
*/}
                              <div className='feature-item-title'>Research session AI note taker</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product2.includes('Integration with Zoom, Meet, Teams') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Integration with Zoom, Meet, Teams</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product2.includes('No duration cap') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>No duration cap</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product2.includes('All analysis features') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>All analysis features</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row-header-row'>

                              <div className='feature-item-title'>Transcription</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product2.includes('Transcribe audio recording') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Transcribe audio recording</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product2.includes('Transcribe video recording') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Transcribe video recording</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product2.includes('Batch upload') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Batch upload</div>
                          </div>
                          <div className="features-hr"></div>

                          <div className='feature-item-row-header-row'>
                              <div className='feature-item-title'>Analysis & Insights</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product2.includes('Affinity Mapping') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Affinity Mapping</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product2.includes('Summary and key insights') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Summary and key insights</div>
                          </div>

                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product2.includes('Question level analysis') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Question level analysis</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product2.includes('Audio recording playback') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Audio recording playback</div>
                          </div>

                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product2.includes('Insightful respondent verbatim') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Insightful respondent verbatim</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row-header-row'>

                              <div className='feature-item-title'>Support</div>
                          </div>
                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product2.includes('Email support') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Email support</div>
                          </div>

                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product2.includes('Chat support') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Chat support</div>
                          </div>

                          <div className="features-hr"></div>
                          <div className='feature-item-row'>
                              <div className='feature-item-icon'>
                                  {disabledFeatures.product2.includes('Phone support') ? '-' : <FaCheck />}
                              </div>

                              <div className='feature-item-title'>Phone support</div>
                          </div>

                      </div>
                  </div>

              </div>

              <div className='enterprise-plan-container'>
                  <div className='enterprise-plan-text-content'>
                      <div className='enterprise-plan-title'>
                          Enterprise  Teams
                      </div>

                      <div className='enterprise-plan-description'>
                          Scale insights org-wide with Enterprise-grade features, access controls, and standardization
                      </div>
                  </div>

                  <button className='contact-button'>Contact us</button>
              </div>

              <div className='faq-section'>
                  <div className="faq-title">
                      Frequently Asked Questions
                  </div>

                  <Accordion>
                      <AccordionSummary className="accordion-summary" expandIcon={<ExpandMoreIcon />}>
                          <div className='faq-question'>What payment methods are accepted?</div>
                      </AccordionSummary>
                      <AccordionDetails className="accordion-details">
                          <div className='faq-answer'>
                              We accept major credit cards, PayPal, and bank transfers for annual payments.
                          </div>
                      </AccordionDetails>
                  </Accordion>

                  <Accordion>
                      <AccordionSummary className="accordion-summary" expandIcon={<ExpandMoreIcon />}>
                          <div className='faq-question'>Is there a free trial available?</div>
                      </AccordionSummary>
                      <AccordionDetails className="accordion-details">
                          <div className='faq-answer'>
                              Yes, we offer a 14-day free trial for all new users. You can experience the full features of our Business plan.
                          </div>
                      </AccordionDetails>
                  </Accordion>

                  <Accordion>
                      <AccordionSummary className="accordion-summary" expandIcon={<ExpandMoreIcon />}>
                          <div className='faq-question'>Can I upgrade or downgrade my plan later?</div>
                      </AccordionSummary>
                      <AccordionDetails className="accordion-details">
                          <div className='faq-answer'>
                              Absolutely. You can change your plan at any time through your account settings.
                          </div>
                      </AccordionDetails>
                  </Accordion>

                  <Accordion>
                      <AccordionSummary className="accordion-summary" expandIcon={<ExpandMoreIcon />}>
                          <div className='faq-question'>How do I cancel my subscription?</div>
                      </AccordionSummary>
                      <AccordionDetails className="accordion-details">
                          <div className='faq-answer'>
                              You can cancel your subscription in your account settings. Your plan will remain active until the end of the billing cycle.
                          </div>
                      </AccordionDetails>
                  </Accordion>
              </div>

              <div className='space'></div>

          </div></>
  );
};

export default Subscriptions;
