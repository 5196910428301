import React from 'react';
import './ProgressBar.css';

const ProgressBar = ({ progress, currentStep }) => {
    
    return (
        <div className="progress-component-progress-bar-container">
            <div className="progress-component-progress-bar-wrapper">
                <div
                    className="progress-component-progress-bar-fill"
                    style={{ width: `${progress}%` }}
                />
            </div>
            <p className="progress-component-progress-status">{currentStep || 'Loading...'}</p>
        </div>
    );
};

export default ProgressBar;
