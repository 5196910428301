// ProgressContainer.js
import React from 'react';
import './ProgressContainer.css';

const ProgressContainer = ({ steps, currentStep, onStepChange }) => {
  return (
    <div className="progress-container">
      {steps.map((stepObj, index) => {
        const isCompleted = index < currentStep;
        const isCurrent = index === currentStep;

        let circleClass = 'progress-container-circle-upcoming';
        if (isCompleted) circleClass = 'progress-container-circle-completed';
        else if (isCurrent) circleClass = 'progress-container-circle-current';

        return (
          // We can use a Fragment so each step + optional line is returned
          <React.Fragment key={index}>
            {/* One progress-step for each progress-container-circle + text */}
            <div className="progress-step">
                <div className='progress-container-circle-line-container'>
                {index == 0 && <div className="line-transparent"></div>}
                {index > 0 && <div className="line"></div>}
              <div
                className={`progress-container-circle ${circleClass}`}
                onClick={() => {
                  if (isCompleted || isCurrent) {
                    onStepChange(index, index < currentStep);
                  }
                }}
              >
                {/* If completed, show a check */}
                {isCompleted && (
                  <svg viewBox="0 0 16 16" fill="none" className="check-icon">
                    <path
                      d="M5 8L7.5 10.5L11 7"
                      strokeWidth="2"
                      stroke="#396E5A"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </div>
              {index < steps.length - 1 && <div className="line"></div>}
              {index == steps.length - 1 && <div className="line-transparent"></div>}
              </div>

              

              {/* Step name below the progress-container-circle */}
              <div
                className={`step-name ${
                  isCompleted
                    ? 'completed-step-name'
                    : isCurrent
                    ? 'current-step-name'
                    : ''
                }`}
                onClick={() => {
                  if (isCompleted || isCurrent) {
                    onStepChange(index, index < currentStep);
                  }
                }}
              >
                {stepObj.name}
              </div>
            </div>

            {/* If not the last progress-container-circle, show a connecting line */}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ProgressContainer;
