import React from 'react';

const TestStep = ({ testLink }) => {
  return (
    <div className="test-step">
    <div className='step-label'> Review interview experience</div>
    <div className='step-description'>
    Give the interview a try and make any tweaks to the discussion guide or the research objectives in the previous steps if you think something's missing.
    </div>
    <button
      className="test-interview-button"
      onClick={() => window.open(`https://feedback.echovane.com/interviews/${testLink}`, '_blank')}
    >
      Test Interview
    </button>

  </div>
  );
};

export default TestStep;
