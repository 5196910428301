import axios from 'axios';
import { toast } from 'react-toastify';

export const createCampaign = async ({ selectedBlock, campaignName, getAccessTokenSilently, getIdTokenClaims, projectType }) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;
  
      const url = 'https://dashboard-api.echovane.com/api/campaigns';
  
      const method = 'POST';
  
      const typeMapping = {
        1: 'consumer_research',
        2: 'ad_testing',
        3: 'concept_testing',
        4: 'consumer_feedback',
      };
  
      const campaignData = {
        name: campaignName,
        type: selectedBlock ? typeMapping[selectedBlock] : null,
        project_type: projectType
      };
  
      const response = await fetch(url, {
        method,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(campaignData),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to create/update campaign: ${errorText}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error creating/updating campaign:', error);
      throw error;
    }
  };
  

export const createInterview = async({ campaignId, getAccessTokenSilently, getIdTokenClaims, interviewType }) => {

  try {
    const accessToken = await getAccessTokenSilently();
    const idTokenClaims = await getIdTokenClaims();
    const idToken = idTokenClaims.__raw;

    const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}/interviews`, {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          interview_type: interviewType  
      })
    });
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to create interview: ${errorText}`);
    }

    return await response.json();

  }
  catch (error) {
    console.error('Error creating interview:', error);
    throw error;
  }

};


export const updateCampaign = async ({campaignData, campaignId, getAccessTokenSilently, getIdTokenClaims}) => {
  try {
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;

      const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}`, {
          method: 'PUT',
          headers: {
              'Authorization': `Bearer ${accessToken}`,
              'X-ID-Token': idToken,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(campaignData)
      });

      if (!response.ok) {
          const errorText = await response.text();
          console.error('Error response:', errorText);
          throw new Error(`Failed to update campaign: ${response.status} ${response.statusText}`);
      }

      const updatedCampaign = await response.json();
      return updatedCampaign;
  } catch (error) {
      console.error('Error updating campaign:', error);
      throw error;
  }
};


export const fetchTranscripts = async ({interviewId, getAccessTokenSilently, getIdTokenClaims}) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idTokenClaims = await getIdTokenClaims();
    const idToken = idTokenClaims.__raw;
    if (interviewId) {
    const response = await fetch(`https://dashboard-api.echovane.com/api/interviews/${interviewId}/transcripts`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-ID-Token': idToken,
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch transcripts');
    }

    const data = await response.json();
    
    return data;
  }
  else {
    // setError('Hey there! It looks like there are no transcripts yet.');
  }
  } catch (error) {
    console.error('Error fetching transcripts:', error);
    // setError('Failed to load transcripts. Please try again later.');
  } 
};

export const fetchCampaignData = async ({campaignId, getAccessTokenSilently, getIdTokenClaims}) => {
  
  if (campaignId) {
    try {
      
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;

      const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const fetchedCampaign = await response.json();
        return fetchedCampaign;
      } else {
        console.error('Failed to fetch campaign data');
      }
    } catch (error) {
      console.error('Error fetching campaign data:', error);
    } 
  }
};


export  const fetchAudioUrl = async ({transcriptId, getAccessTokenSilently, getIdTokenClaims}) => {
  try {

    
    const accessToken = await getAccessTokenSilently();
    const idToken = await getIdTokenClaims();



    const response = await fetch(`https://dashboard-api.echovane.com/api/interviews/transcripts/${transcriptId}/presigned-url`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-ID-Token': idToken.__raw,
      }
    });

    const data = await response.json();
 
    if (!response.ok) {
      throw new Error('Failed to fetch audio URL');
    }
     return data;

  } catch (error) {
    console.error('Error fetching audio URL:', error);
  } 
};


export const generateCampaignDiscussionGuide = async ({campaignId, getAccessTokenSilently, getIdTokenClaims, instruction}) => {
  const payload = instruction ? { instruction } : {};

  const accessToken = await getAccessTokenSilently();
  const idTokenClaims = await getIdTokenClaims();
  const idToken = idTokenClaims.__raw;

  const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}/generate-questions`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'X-ID-Token': idToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to generate discussion guide: ${errorText}`);
  }

  const data = await response.json();
  
  return data;
};

export const createOrUpdateCampaign = async ({campaignId, getAccessTokenSilently, getIdTokenClaims, campaignData}) => {

  const accessToken = await getAccessTokenSilently();
  const idTokenClaims = await getIdTokenClaims();
  const idToken = idTokenClaims.__raw;

  const url = campaignId && campaignId !== 'null'
    ? `https://dashboard-api.echovane.com/api/campaigns/${campaignId}`
    : 'https://dashboard-api.echovane.com/api/campaigns';

  const method = campaignId ? 'PUT' : 'POST';

  const response = await fetch(url, {
    method: method,
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'X-ID-Token': idToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(campaignData)
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to create/update campaign: ${errorText}`);
  }

  const data = await response.json();
  return data;
};

export const requestPresignedUrlsAnalyze = async ({ interviewId, fileNames, fileSizes, getAccessTokenSilently, getIdTokenClaims }) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idToken = (await getIdTokenClaims()).__raw;

    const response = await axios.post(
      `https://dashboard-api.echovane.com/api/analyze/presigned-urls`,
      { interview_id: interviewId, file_names: fileNames, file_sizes: fileSizes },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
        },
      }
    );

    const data = response.data;
    return data; 
  } catch (error) {
    console.error('Error requesting presigned URLs:', error);
    throw error;
  }
};

export const completeMultipartUpload = async ({ interviewId, transcriptId, fileName, uploadId, etags, getAccessTokenSilently, getIdTokenClaims }) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idToken = (await getIdTokenClaims()).__raw;

    await axios.post(
      `https://dashboard-api.echovane.com/api/analyze/complete-multipart-upload`,
      {
        interview_id: interviewId,
        transcript_id: transcriptId,
        file_name: fileName,
        upload_id: uploadId,
        parts: etags,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
        },
      }
    );
  } catch (error) {
    console.error(`Error completing multipart upload for ${fileName}:`, error);
    throw error;
  }
};

export const abortMultipartUpload = async ({ interviewId, transcriptId, fileName, uploadId, getAccessTokenSilently, getIdTokenClaims }) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idToken = (await getIdTokenClaims()).__raw;

    await axios.post(
      `https://dashboard-api.echovane.com/api/analyze/abort-multipart-upload`,
      {
        interview_id: interviewId,
        transcript_id: transcriptId,
        file_name: fileName,
        upload_id: uploadId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
        },
      }
    );
  } catch (error) {
    console.error(`Error aborting multipart upload for ${fileName}:`, error);
    throw error;
  }
};

export const analyzeInterviews = async ({ campaignId, interviewId, refreshAnalysis = false, getAccessTokenSilently, getIdTokenClaims }) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idToken = (await getIdTokenClaims()).__raw;

    const response = await fetch(`https://dashboard-api.echovane.com/api/analyze/analyze-interviews`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'X-ID-Token': idToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        campaign_id: campaignId,
        interview_id: interviewId,
        refresh_analysis: refreshAnalysis,
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      throw new Error(`Failed to analyze interviews: ${response.status} ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error during interview analysis:', error);
    throw error;
  }
};

export const deleteTranscript = async ({ transcriptId, getAccessTokenSilently, getIdTokenClaims }) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idToken = (await getIdTokenClaims()).__raw;

    await axios.delete(
      `https://dashboard-api.echovane.com/api/interviews/transcripts/${transcriptId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
        },
      }
    );
  } catch (error) {
    console.error('Error deleting transcript:', error);
    throw error;
  }
};

export const requestPresignedUrls = async ({ clientName, fileNames, fileSizes, uniqueId = null, getAccessTokenSilently, getIdTokenClaims  }) => {
  
  const accessToken = await getAccessTokenSilently();
  const idToken = (await getIdTokenClaims()).__raw;
  
  const payload = {
    client_name: clientName,
    file_names: fileNames,
    file_sizes: fileSizes,
    unique_id: uniqueId, 
    create_record: true
  };

  const response = await axios.post('https://dashboard-api.echovane.com/api/upload/presigned-urls', 
    payload,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'X-ID-Token': idToken,
      },
    }
  );
  const data = response.data;
  
  return data; 
};

export const deleteFile = async ({ campaignId, fileId, getAccessTokenSilently, getIdTokenClaims }) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idToken = (await getIdTokenClaims()).__raw;

    
    const response = await fetch('https://dashboard-api.echovane.com/api/upload/delete-creative', {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-ID-Token': idToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        campaign_id: campaignId,
        id: fileId
      })
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to delete file: ${errorText}`);
    }

    const data = await response.json();
    return data; 
  } catch (error) {
    console.error('Error deleting file:', error);
    throw error;
  }
};

export const fetchFileDownloadUrl = async ({ campaignId, fileId, getAccessTokenSilently, getIdTokenClaims }) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idToken = (await getIdTokenClaims()).__raw;

    const response = await fetch(`https://dashboard-api.echovane.com/api/upload/${campaignId}/download-url?id=${fileId}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-ID-Token': idToken,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch file download URL');
    }

    const data = await response.json();
    return data.file_url;
  } catch (error) {
    console.error('Error fetching file download URL:', error);
    throw error;
  }
};

export const addNewAttribute = async ({ label, description, getAccessTokenSilently, getIdTokenClaims }) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idTokenClaims = await getIdTokenClaims();
    const idToken = idTokenClaims.__raw;

    const response = await fetch('https://dashboard-api.echovane.com/api/campaigns/concept-testing/attributes', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-ID-Token': idToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        attribute_name: label,
        description: description,
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to add new attribute: ${errorText}`);
    }

    const data = await response.json();
    return data; // Returns { attribute_id: <id> }
  } catch (error) {
    console.error('Error adding new attribute:', error);
    throw error;
  }
};


export const fetchAttributes = async ({ getAccessTokenSilently, getIdTokenClaims }) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idTokenClaims = await getIdTokenClaims();
    const idToken = idTokenClaims.__raw;

    const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/concept-testing/attributes`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-ID-Token': idToken
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to fetch attributes: ${errorText}`);
    }

    const attributes = await response.json();
    
    return attributes; // Return the list of attributes
  } catch (error) {
    console.error('Error fetching attributes:', error);
    throw error;
  }
};


export const updateDiscussionGuide = async ({ campaignId, discussionGuide, getAccessTokenSilently, getIdTokenClaims }) => {
  try {
    
    const accessToken = await getAccessTokenSilently();
    const idTokenClaims = await getIdTokenClaims();
    const idToken = idTokenClaims.__raw;

    const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}/topics`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-ID-Token': idToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(discussionGuide)
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to update topics data: ${errorText}`);
    }

    const updatedData = await response.json();
    return updatedData;
  } catch (error) {
    console.error('Error updating topics data:', error);
    throw error;
  }
};


export const updateCampaignDiscussionGuide = async ({ campaignId, getAccessTokenSilently, getIdTokenClaims, oldCampaignData }) => {
  const accessToken = await getAccessTokenSilently();
  const idTokenClaims = await getIdTokenClaims();
  const idToken = idTokenClaims.__raw;

  const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}/update-discussion-guide`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'X-ID-Token': idToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ oldCampaignData })
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to update discussion guide: ${errorText}`);
  }

  const data = await response.json();
  
  return data;
};


export const generateSingleTopicQuestions = async ({
  getAccessTokenSilently,
  getIdTokenClaims,
  userContent
}) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idTokenClaims = await getIdTokenClaims();
    const idToken = idTokenClaims.__raw;

    const url = 'https://dashboard-api.echovane.com/api/wizard/call';

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-ID-Token': idToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        identifier: 'generate_single-topic-questions',
        user_content: JSON.stringify(userContent), 
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to generate single topic questions: ${errorText}`);
    }

    const data = await response.json();
    
    
    return data;
  } catch (error) {
    console.error('Error generating single topic questions:', error);
    throw error;
  }
};


export const createTopUpCheckoutSession = async ({ amount, getAccessTokenSilently, getIdTokenClaims }) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idTokenClaims = await getIdTokenClaims();
    const idToken = idTokenClaims.__raw;

    const response = await fetch('https://dashboard-api.echovane.com/api/subscriptions/top_up_recruit_balance', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'X-ID-Token': idToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ amount })
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to create top-up session: ${errorText}`);
    }

    const data = await response.json();
    // data should contain { checkout_url: "..." }
    return data.checkout_url; 
  } catch (error) {
    console.error('Error creating top-up checkout session:', error);
    throw error;
  }
};




/**
 * Upgrade Subscription
 */
export const upgradeSubscription = async ({
  getAccessTokenSilently,
  getIdTokenClaims,
  selectedPlans
}) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idTokenClaims = await getIdTokenClaims();
    const idToken = idTokenClaims.__raw;

    const response = await fetch(`https://dashboard-api.echovane.com/api/subscriptions/upgrade_subscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'X-ID-Token': idToken,
      },
      body: JSON.stringify({
        product1_plan_name: selectedPlans.product1,
        product2_plan_name: selectedPlans.product2,
      }),
    });

    if (!response.ok) {
      // We can read the response to get some error details if needed
      const errorText = await response.text();
      throw new Error(`Failed to initiate subscription upgrade: ${errorText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error upgrading subscription:', error);
    throw error; // Let the caller handle the error (e.g., setLoading(false))
  }
};


/**
 * Cancel Subscription
 */
export const cancelSubscription = async ({
  getAccessTokenSilently,
  getIdTokenClaims
}) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idTokenClaims = await getIdTokenClaims();
    const idToken = idTokenClaims.__raw;

    const response = await fetch(`https://dashboard-api.echovane.com/api/subscriptions/cancel_subscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'X-ID-Token': idToken,
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to cancel subscription: ${errorText}`);
    }

    // Successfully canceled
    const data = await response.json();
    return data; // Might contain a message or other info
  } catch (error) {
    console.error('Error canceling subscription:', error);
    throw error;
  }
};


/**
 * Verify Payment
 */
export const verifyPayment = async ({
  sessionId,
  getAccessTokenSilently,
  getIdTokenClaims
}) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idTokenClaims = await getIdTokenClaims();
    const idToken = idTokenClaims.__raw;

    const response = await fetch(
      `https://dashboard-api.echovane.com/api/subscriptions/verify_payment`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'X-ID-Token': idToken,
        },
        body: JSON.stringify({ session_id: sessionId }),
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to verify payment: ${errorText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error verifying payment:', error);
    throw error;
  }
};


/**
 * Fetch Plan Data
 */
export const fetchPlanDataAPI = async ({
  getAccessTokenSilently,
  getIdTokenClaims
}) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const idTokenClaims = await getIdTokenClaims();
    const idToken = idTokenClaims.__raw;

    const response = await fetch('https://dashboard-api.echovane.com/api/usage', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'X-ID-Token': idToken,
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to fetch plan data: ${errorText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching plan data:', error);
    throw error;
  }
};
