// CreativeOptionsStep.js

import React from 'react';
import { FaImage, FaVideo, FaArrowRight, FaColumns } from 'react-icons/fa';

const CreativeOptionsStep = ({
  selectedCreativeType,
  setSelectedCreativeType,
  selectedCreativeQuantity,
  setSelectedCreativeQuantity,
  selectedCreativeTestMethod,
  setSelectedCreativeTestMethod,
  testDisplayMethod,
  clearValidationError,
  validationErrors
}) => {

  const isThirdQuestionVisible = selectedCreativeQuantity === 'all';


  const quantityOptions = [
    { label: '1', value: '1' },
    { label: '2', value: 'all' },
  ];

  return (
    <div className="creative-options-step">
      <div className="step-label">Choose Concept Options</div>

      {/* First Question */}
      <div className="creative-option-block">
        <div className="creative-option-block-text">Type of Concept</div>
        <div className="options-row">
          <div
            className={`option-box ${selectedCreativeType === 'image' ? 'selected' : ''}`}
            onClick={() => {
              setSelectedCreativeType('image');
              clearValidationError('creativeType'); 
            }
            }
            
          >
            <FaImage className="option-icon" />
            <span className="option-text">Image</span>
          </div>
          <div
            className={`option-box ${selectedCreativeType === 'video' ? 'selected' : ''}`}
            onClick={() => {
              setSelectedCreativeType('video');
              clearValidationError('creativeType'); 
            }
            }
          >
            <FaVideo className="option-icon" />
            <span className="option-text">Video</span>
          </div>
        </div>
        <hr className="full-width-line-review" />
        <div className="err-message">
          {validationErrors.creativeType && <p>{validationErrors.creativeType}</p>}
          
        </div>

      </div>


      {/* Second Question */}
      <div className="creative-option-block">
        <div className="creative-option-block-text">Number of Concepts to test per participant</div>
        <div className="options-row">
          {quantityOptions.map((option) => (
            <div
              key={option.value}
              className={`option-box ${selectedCreativeQuantity === option.value ? 'selected' : ''}`}
              onClick={() => {
                setSelectedCreativeQuantity(option.value);
                clearValidationError('creativeQuantity');
              }}
            >
              <span className="option-text">{option.label}</span>
            </div>
          ))}
        </div>

        <hr className="full-width-line-review" />
        <div className="err-message">
          {validationErrors.creativeQuantity && <p>{validationErrors.creativeQuantity}</p>}
        </div>

      </div>



      {/* Third Question */}
      {isThirdQuestionVisible && (
        <div className="creative-option-block">
          <div className="creative-option-block-text">How do you want to test the creatives?</div>
          <div className="options-row">
            <div
              className={`option-box ${selectedCreativeTestMethod === 'sequential' ? 'selected' : ''}`}
              onClick={() => {
                setSelectedCreativeTestMethod('sequential');
                clearValidationError("creativeTestMethod")
              }
              }
            >
              
                <FaArrowRight  className="option-icon" />
              
             < span className="option-text">Sequentially</span>

            </div>
            <div
              className={`option-box ${selectedCreativeTestMethod === 'side_by_side' ? 'selected' : ''}`}
              onClick={() => {
                setSelectedCreativeTestMethod('side_by_side');
                clearValidationError("creativeTestMethod")
              }
              }
            >
              
                <FaColumns  className="option-icon"/>
              
              < span className="option-text">Side by Side</span>

            </div>
          </div>
          <hr className="full-width-line-review" />
          <div className="err-message">
          {validationErrors.creativeTestMethod && <p>{validationErrors.creativeTestMethod}</p>}
          
        </div>
        </div>
      )}

    </div>
  );
};

export default CreativeOptionsStep;
