import React from 'react';
import consumerResearchImg from '../../assets/consumer_research.svg';
import conceptTestingImg from '../../assets/concept_testing.svg';
import consumerFeedbackImg from '../../assets/consumer_feedback.svg';

const ResearchTypeStep = ({ selectedBlock, onSelectBlock }) => {
  const options = [
    {
      id: 1,
      title: 'Consumer Research',
      description: 'Choose for more open ended conversations. Good for exploratory research on topics vs specific questions. Understand behaviours, opinions and preferences of your target audience.',
      img: consumerResearchImg,
    },
    {
      id: 3,
      title: 'Concept Testing',
      description: 'Choose to test new ideas, products, or campaigns. Ideal for understanding audience reactions, identifying strengths, and refining concepts before launch.',
      img: conceptTestingImg,
    },
    {
      id: 4,
      title: 'User Feedback',
      description: 'Choose for specific feedback on a more or less known set of questions. Get user feedback on your current products and services.',
      img: consumerFeedbackImg,
    },
  ];

  return (
    <div className="research-type-step">
      <div className="step-label">Choose type of research</div>
      <div className="research-grid">
        <div className="research-options">
        {options.map((block) => (
          <div
            className={`research-block ${block.id === selectedBlock ? 'selected' : ''}`}
            key={block.id}
            onClick={() => onSelectBlock(block.id)}
          >
            <div className="radio-container">
              <input
                type="radio"
                id={`radio-${block.id}`}
                name="researchBlock"
                checked={block.id === selectedBlock}
                onChange={() => onSelectBlock(block.id)}
              />
              <span className="custom-radio-circle"></span>
              
            </div>
            <img src={block.img} alt={block.title} className="research-image" />
            <div className="block-text">
              <h2>{block.title}</h2>
              <p>{block.description}</p>
            </div>
          </div>
        ))}

        </div>
      </div>
    </div>
  );
};

export default ResearchTypeStep;

