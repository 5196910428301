import React, { useState, useEffect, useRef , useMemo} from 'react';
import './CampaignReport.css';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faLightbulb, faCheckCircle, faArrowLeft, faDownload, faChevronLeft, faChevronRight, faBars, faPlay, faObjectGroup, faFlask} from '@fortawesome/free-solid-svg-icons';
import mixpanel from 'mixpanel-browser';
import * as XLSX from 'xlsx';
import { useAuth0 } from '@auth0/auth0-react';
import AffinityCanvas from './AffinityCanvas';
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { fetchCampaignData} from './ApiUtils';
import cross from '../assets/cross.svg';

mixpanel.init('1702428ef9acdb5aa84df81256d5fe13', { debug: false, track_pageview: false, persistence: 'localStorage' });


const themeColors = [
  "#6B53F9",
  "#FD6851",
  "#379E6C",
  "#F6B242",
  "#A84F63",
  "#68B7EF",
  "#FEA773",
  "#137494",
  "#379E6C",
  "#FDB2AA",
  "#C277D3",
  "#53AEA6"
];

function getRatingStyle(rating) {
  if (rating <= 3) {
    // Red zone gradient
    if (rating <= 1) {
      // 0 or 1
      return rating <= 0.5
        ? { backgroundColor: '#f1b0b7', border: '1px solid #ee9fa8' }
        : { backgroundColor: '#f3b7bd', border: '1px solid #efacb2' };
    } else if (rating <= 2) {
      // rating between 1 and 2
      return { backgroundColor: '#f5bec2', border: '1px solid #f1b5bb' };
    } else {
      // rating between 2 and 3
      return { backgroundColor: '#f8d7da', border: '1px solid #f5c6cb' };
    }
  } else if (rating <= 7) {
    // Yellow zone uniform
    return { backgroundColor: '#fff3cd', border: '1px solid #ffeeba', color: '#856404' };
  } else {
    // Green zone gradient
    if (rating < 9) {
      // rating 8.x
      return { backgroundColor: '#c3e6cb', border: '1px solid #b3dfbb' };
    } else if (rating < 10) {
      // rating 9.x
      return { backgroundColor: '#cddfd2', border: '1px solid #bdd7c4' };
    } else {
      // rating = 10
      return { backgroundColor: '#d4edda', border: '1px solid #c3e6cb' };
    }
  }
}




const affinityThemes = [
  {
    theme: "Customer Experience",
    verbatim: [
      "The process was smooth and easy.",
      "I found the customer service very helpful.",
      "It could be better, but overall I'm satisfied."
    ]
  },
  {
    theme: "Product Quality",
    verbatim: [
      "The product met my expectations in terms of quality.",
      "There were some minor issues, but it works well.",
      "I love the design and build quality of the product."
    ]
  },
  {
    theme: "Pricing",
    verbatim: [
      "I think the pricing is reasonable.",
      "It’s a bit expensive for the features offered.",
      "Good value for money."
    ]
  }
];


const CampaignReport = () => {
  const { campaignId } = useParams();
  
  const location = useLocation();
  const navigate = useNavigate();
  const [reportData, setReportData] = useState(null);
  const [questionAnalysisData, setQuestionAnalysisData] = useState(null);
  const [selectedThemes, setSelectedThemes] = useState({});
  const [loading, setLoading] = useState(true); // New loading state
  const [mode, setMode] = useState('test');
  const [error, setError] = useState(null);
  const [respondentCount, setRespondentCount] = useState(0);
  const [transcripts, setTranscripts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedTranscript, setSelectedTranscript] = useState(null);
  const [testLink, setTestLink] = useState(null);
  const [liveLink, setLiveLink] = useState(null);
  const [projectType, setProjectType] = useState(null);
  const [campaignType, setCampaignType] = useState(null);
  const [conceptTestData, setConceptTestData] = useState(null);
  const [expandedAttribute, setExpandedAttribute] = useState(null); 
  const [selectedSummary, setSelectedSummary] = useState(null); 
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [conceptDetails, setConceptDetails] = useState({});
  let concepts = [];
  const sectionRefs = useRef({});
  const [fullScreenMedia, setFullScreenMedia] = useState(null);
  const navItems = [
    { id: 'research-summary', label: 'Research Summary', icon: faFileAlt },
    { id: 'key-findings', label: 'Key Findings', icon: faLightbulb },
    { id: 'recommendations', label: 'Recommendations', icon: faCheckCircle },
    { id: 'question-analysis', label: 'Question Analysis', icon: faLightbulb },
    { id: 'transcripts', label: 'Transcripts', icon: faFileAlt },
    { id: 'affinity-mapping', label: 'Affinity Mapping', icon: faObjectGroup }
  ];
  
    
  const [drawerOpen, setDrawerOpen] = useState(true);
const [showText, setShowText] = useState(true);  // By default false, so that text is hidden when collapsed

const drawerRef = useRef(null);

const openDrawer = () => {
  setDrawerOpen(true);
  setShowText(true);  
};



  const [activeSection, setActiveSection] = useState('research-summary');
  const [expandedCells, setExpandedCells] = useState({});
  const [totalCount, setTotalCount] = useState(0);  
  const [transcriptLoading, setTranscriptLoading] = useState(false); 
  const [audioUrl, setAudioUrl] = useState(null); 
  const [audioLoading, setAudioLoading] = useState(false); 
  const audioRef = useRef(null); 
  const { loginWithRedirect, logout, user, isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0();


  const toggleAttribute = (attrId) => {
    setExpandedAttribute((prev) => (prev === attrId ? null : attrId));
  };
  const [selectedImpressionType, setSelectedImpressionType] = useState(null); 


  let attributes = [];
  

  let conceptToNameMap = {}; 


  let firstImpressionsLookup = {};
if (conceptTestData?.summary_first_impressions) {
  conceptTestData.summary_first_impressions.forEach(item => {
    firstImpressionsLookup[item.concept_id] = item.first_impressions_summary;
  });
}

let comparativeImpressionsLookup = {};
if (conceptTestData?.summary_comparative_impressions) {
  conceptTestData.summary_comparative_impressions.forEach(item => {
    comparativeImpressionsLookup[item.concept_id] = item.comparative_impressions_summary;
  });
}


// Detailed first impressions keyed by concept_id
let detailedFirstImpressions = {};
if (conceptTestData?.details_first_impressions) {
  conceptTestData.details_first_impressions.forEach(item => {
    if (!detailedFirstImpressions[item.concept_id]) {
      detailedFirstImpressions[item.concept_id] = [];
    }
    detailedFirstImpressions[item.concept_id].push(item.first_impression);
  });
}

// Detailed comparative impressions keyed by concept_id
// Note: comparative impressions has "compared_to_concept_id" as well. You can store pairs.
let detailedComparativeImpressions = {};
if (conceptTestData?.details_comparative_impressions) {
  conceptTestData.details_comparative_impressions.forEach(item => {
    if (!detailedComparativeImpressions[item.concept_id]) {
      detailedComparativeImpressions[item.concept_id] = [];
    }
    // Include comparison detail and mention other concept in the detail if desired
    detailedComparativeImpressions[item.concept_id].push(item.detail);
  });
}




  if (conceptTestData?.details_why_aggregated_concept_vs_attribute_ratings_table) {
    const detailsData = conceptTestData.details_why_aggregated_concept_vs_attribute_ratings_table;
    // Extract attributes and concepts
    concepts = Object.keys(detailsData); // concept_ids
    // Collect all attributes
    const attributeSet = new Set();
    concepts.forEach(conceptId => {
      const attrData = detailsData[conceptId];
      Object.keys(attrData).forEach(attrId => attributeSet.add(attrId));
    });
    attributes = Array.from(attributeSet);

    // Create concept name map
    concepts.forEach((cId, index) => {
      conceptToNameMap[cId] = `Concept ${String.fromCharCode(65 + index)}`; // Concept A, Concept B...
    });
  }

  // Create a lookup for summary data
  let summaryLookup = {};
  if (conceptTestData?.summary_why_aggregated_concept_vs_attribute_ratings_table) {
    conceptTestData.summary_why_aggregated_concept_vs_attribute_ratings_table.forEach(item => {
      const key = `${item.concept_id}-${item.attribute_id}`;
      summaryLookup[key] = item;
    });
  }

  const finalizedConcepts = useMemo(() => {
    if (conceptTestData?.details_why_aggregated_concept_vs_attribute_ratings_table) {
      const detailsData = conceptTestData.details_why_aggregated_concept_vs_attribute_ratings_table;
      return Object.keys(detailsData); // Parse concepts only when conceptTestData is available
    }
    return [];
  }, [conceptTestData]);


  useEffect(() => {
    const fetchConceptDetails = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const idTokenClaims = await getIdTokenClaims();
        const idToken = idTokenClaims.__raw;
  
        if (concepts.length > 0) {
          const response = await fetch('https://dashboard-api.echovane.com/api/upload/concept-details', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'X-ID-Token': idToken,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ concept_ids: concepts }),
          });
  
          if (!response.ok) {
            throw new Error('Failed to fetch concept details.');
          }
  
          const data = await response.json();
          const details = {};
          data.forEach((item) => {
            details[item.concept_id] = item;
          });
  
          setConceptDetails(details);
          
        }
      } catch (error) {
        console.error('Error fetching concept details:', error);
      }
    };
  
    fetchConceptDetails();
  }, [finalizedConcepts]);
  

  useEffect(() => {
    mixpanel.track('ReportPageViewed');
  }, []);

  useEffect(() => {
    if (location.state?.section) {
      const sectionRef = sectionRefs.current[location.state.section];
      if (sectionRef) {
        const headerHeight = 100; // Adjust based on your UI
        const yOffset = headerHeight + 20; // Extra padding
        const y = sectionRef.getBoundingClientRect().top + window.pageYOffset - yOffset;
  
        window.scrollTo({ top: y, behavior: 'smooth' });
        setActiveSection(location.state.section);
      }
    }
  }, [location.state?.section]);

  const openSidePanel = (conceptId, attributeId) => {
    const detailsData = conceptTestData.details_why_aggregated_concept_vs_attribute_ratings_table;
    const whyList = detailsData[conceptId][attributeId].why_list;
    const summaryObj = summaryLookup[`${conceptId}-${attributeId}`];
    setSelectedImpressionType(null);

    setSelectedSummary({
      conceptId: conceptId,
      attributeId: attributeId,
      summary: summaryObj?.rating_reason_summary,
      avgRating: summaryObj?.avg_rating,
      whyList: whyList
    });
    setShowSidePanel(true);
  };

  const closeSidePanel = () => {
    setShowSidePanel(false);
    setSelectedSummary(null);
  };



  useEffect(() => {
    const loadCampaignData = async () => {
      try {
        setLoading(true);
        const data = await fetchCampaignData({campaignId: campaignId, getAccessTokenSilently, getIdTokenClaims});
        if (data) {
          setTestLink(data.test_link); // Update state with testLink
          setLiveLink(data.live_link); // Update state with liveLink
          setCampaignType(data.type);
          setProjectType(data.project_type);
          if (data.project_type === "transcribe_analyse") {
            
            setMode('live');
          } else{
            setMode('test');
          }

        }
      } catch (error) {
        console.error('Error fetching campaign data:', error);
        setError('Failed to fetch campaign data.');
      }
      finally {
        setLoading(false);
      }
    };
  
    if (campaignId) {
      loadCampaignData();
    }
  }, []);
  

  useEffect(() => {
    const fetchData = async (url, setter) => {
      setLoading(true);
      setError(null);
      try {
        const accessToken = await getAccessTokenSilently();
        const idTokenClaims = await getIdTokenClaims();
        const idToken = idTokenClaims.__raw;

        const response = await fetch(url, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'X-ID-Token': idToken,
          }
        });

        if (!response.ok) {
          if (response.status === 404) {
            if  (projectType === "ai_moderated") {
            throw new Error('No respondents have completed an interview yet, please check back again after some time.');
            }
            else {
              throw new Error('Insights have not been generated yet, please check back in a few minutes.');
            }
          }
          const errorText = await response.text();
          throw new Error(errorText || 'Something went wrong, we are on it.');
        }

        const data = await response.json();
       
        
        setter(data);
        if (url.includes('question-analysis')) {
          setRespondentCount(data.version);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message || 'Failed to load data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    
    const interviewId = mode === 'test' ? testLink : liveLink;

    if (interviewId) {
      if (campaignType === "concept_testing") {
         fetchConceptTestingInsights(interviewId); 
        }
      fetchData(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}/interviews/${interviewId}/insights`, data => setReportData(data));
      fetchData(`https://dashboard-api.echovane.com/api/campaigns/${campaignId}/interviews/${interviewId}/question-analysis`, data => setQuestionAnalysisData(data.question_analysis?.question_analysis));
    } else {

      if (projectType === 'ai_moderated') {

        setLoading(false);
        setError('Hey there! It looks like you haven\'t generated any interview link yet. To get started, simply complete the \'Create Research\' steps, and once your interviews are live, you\'ll begin seeing insightful results here.');
      }
      else if(projectType === 'transcribe_analyse')  {
        setError('Hey there! It looks like you haven\'t uploaded any recordings to analyze yet. To get started, simply complete the \'Upload recordings\' steps, and once you submit, you\'ll begin seeing insightful results here.');
      }

      
    }
  }, [ testLink, liveLink, mode]);


  useEffect(() => {
    const fetchTranscripts = async () => {
      setTranscriptLoading(true); 
      setError(null);
      try {


        const accessToken = await getAccessTokenSilently();
        const idTokenClaims = await getIdTokenClaims();
        const idToken = idTokenClaims.__raw;
        const interviewId = mode === 'test' ? testLink : liveLink;
        if (interviewId) {
        const response = await fetch(`https://dashboard-api.echovane.com/api/interviews/${interviewId}/transcripts?page=${currentPage}&page_size=10`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'X-ID-Token': idToken,
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch transcripts');
        }

        const data = await response.json();

        setTranscripts(data.transcripts);
        setTotalCount(data.total_count);  
        setTotalPages(Math.ceil(data.total_count / 10));

        // Set the first transcript as selected by default
        if (data.transcripts.length > 0 && !selectedTranscript) {
          const firstTranscript = data.transcripts[0];
          setSelectedTranscript(firstTranscript);
          fetchAudioUrl(firstTranscript.id);
        }
      }
      else {
        if (projectType === 'ai_moderated') {

          setLoading(false);
          setError('Hey there! It looks like you haven\'t generated any interview link yet. To get started, simply complete the \'Create Research\' steps, and once your interviews are live, you\'ll begin seeing insightful results here.');
        }
        else if(projectType === 'transcribe_analyse')  {
          setError('Hey there! It looks like you haven\'t uploaded any recordings to analyze yet. To get started, simply complete the \'Upload recordings\' steps, and once you submit, you\'ll begin seeing insightful results here.');
        }
      }
      } catch (error) {
        console.error('Error fetching transcripts:', error);
        setError('Failed to load transcripts. Please try again later.');
      } finally {
        setTranscriptLoading(false); 
      }
    };

    if (campaignId) {
      fetchTranscripts();
    }
  }, [campaignId, testLink, liveLink, mode, currentPage]);


  const fetchConceptTestingInsights = async (interviewId) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;

      const response = await fetch(
        `https://dashboard-api.echovane.com/api/campaigns/${interviewId}/concept-testing-insights`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "X-ID-Token": idToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch concept testing insights.");
      }

      const conceptInsights = await response.json();
      console.log("concept insights ", conceptInsights);
      setConceptTestData(conceptInsights); 
    } catch (error) {
      console.error("Error fetching concept testing insights:", error);
    }
  };



  const fetchAudioUrl = async (transcriptId) => {
    setAudioLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();

      const response = await fetch(`https://dashboard-api.echovane.com/api/interviews/transcripts/${transcriptId}/presigned-url`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'X-ID-Token': idToken.__raw,
        }
      });

      const data = await response.json();

   
      if (!response.ok) {
        throw new Error('Failed to fetch audio URL');
      }
      
  
      setAudioUrl(data.presigned_url); 

    } catch (error) {
      console.error('Error fetching audio URL:', error);
    } finally {
      setAudioLoading(false);
    }
  };


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setSelectedTranscript(null);  
  };
  
  const handleTranscriptClick = (transcript) => {
    setSelectedTranscript(transcript);
    fetchAudioUrl(transcript.id);
  };

  const toggleCellExpansion = (rowIndex, cellIndex) => {
    setExpandedCells(prev => {
      const key = `${rowIndex}-${cellIndex}`;
      return {
        ...prev,
        [key]: !prev[key]
      };
    });
  };

  const containerRef = useRef(null); // Attach this ref to .campaign-report-container

  const scrollToSection = (sectionId) => {
    const container = containerRef.current;
    const sectionRef = sectionRefs.current[sectionId];
    
    if (container && sectionRef) {
      // Distance from section to the container's top
      const containerTop = container.getBoundingClientRect().top;
      const sectionTop = sectionRef.getBoundingClientRect().top;
      const headerHeight = 100;
      const extraPadding = 20;
      
      // offset is how far the section is from the container top
      const offset = (sectionTop - containerTop) - (headerHeight + extraPadding);
      
      container.scrollTo({
        top: container.scrollTop + offset,
        behavior: 'smooth'
      });
      
      setActiveSection(sectionId);
    }
  };
  






  const formatTranscript = (transcript) => {
    const lines = transcript.split('\n');
  
    return lines.map((line, index) => {
      // Check for specific keywords and apply special formatting
      if (line.includes("USER_LONG_SILENCE_DETECTED")) {
        return (
          <div key={index} className="transcript-special">
            <span style={{ fontSize: '0.8em', textAlign: 'center', width: '100%' }}>
              User didn't speak for a while
            </span>
          </div>
        );
      } else if (line.includes("ECHO_LONG_SILENCE_DETECTED")) {
        return (
          <div key={index} className="transcript-special">
            <span style={{ fontSize: '0.8em', textAlign: 'center', width: '100%' }}>
              Echo got interrupted
            </span>
          </div>
        );
      }
  
      // Format 1 and Format 3 handling (timestamp-based formats)
      const timestampFormatMatch = line.match(
        /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} - (User|Echo|Speaker \d+): (.*)$/
      );
      if (timestampFormatMatch) {
        const [, speaker, text] = timestampFormatMatch;
        return (
          <div key={index} className={`transcript-line ${speaker.toLowerCase().replace(' ', '-')}`}>
            <div className="speaker">{speaker}</div>
            <div className="text">{text}</div>
          </div>
        );
      }
  
      // Format 2 handling ([Speaker X]: format)
      const speakerFormatMatch = line.match(/^\[(Speaker \d+)\]: (.*)$/);
      if (speakerFormatMatch) {
        const [, speaker, text] = speakerFormatMatch;
        return (
          <div key={index} className={`transcript-line ${speaker.toLowerCase().replace(' ', '-')}`}>
            <div className="speaker">{speaker}</div>
            <div className="text">{text}</div>
          </div>
        );
      }
  
      // Catch-all for any lines that don't match known patterns
      return null;
    }).filter(Boolean); // Remove null entries from the output
  };
  
  


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const suffix = day === 1 || day === 21 || day === 31 ? 'st' : day === 2 || day === 22 ? 'nd' : day === 3 || day === 23 ? 'rd' : 'th';
    return `${day}${suffix} ${month}, ${hour}:${minute} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
  };


  const handleBack = () => {
    navigate(-1); // 
  };

  const toggleMode = () => {
    setMode(prevMode => prevMode === 'test' ? 'live' : 'test');
    setLoading(true); 
  };


  

  const openFullScreenMedia = (mediaUrl) => {
    setFullScreenMedia(mediaUrl);
  };
  
  const closeFullScreenMedia = () => {
    setFullScreenMedia(null);
  };
  

  useEffect(() => {
    if (questionAnalysisData) {
      const initialSelectedThemes = {};
  
      // Loop through each question and sort themes by percentage
      Object.keys(questionAnalysisData).forEach((questionKey) => {
        const themes = questionAnalysisData[questionKey]?.themes || [];
        
        // Sort themes by percentage in descending order
        const sortedThemes = themes.sort((a, b) => b.percentage - a.percentage);
        
        if (sortedThemes.length > 0) {
          // Select the highest percentage theme by default
          initialSelectedThemes[questionKey] = sortedThemes[0].theme;
        }
      });
  
      setSelectedThemes(initialSelectedThemes);
  
      // Display arrows for the initially selected highest percentage themes
      setTimeout(() => {
        Object.entries(initialSelectedThemes).forEach(([questionKey, theme]) => {
          // Find the index of the selected theme to match color
          const themeIndex = questionAnalysisData[questionKey].themes.findIndex(t => t.theme === theme);
          const selectedBar = document.querySelector(`[data-question="${questionKey}"][data-theme="${theme}"] .theme-bar`);
  
          if (selectedBar) {
            const arrow = selectedBar.querySelector('.theme-bar-arrow');
            if (arrow) {
              arrow.style.display = 'block';
              arrow.style.borderLeftColor = themeColors[themeIndex % themeColors.length]; // Match arrow color
            }
          }
        });
      }, 0);
    }
  }, [questionAnalysisData]);
  
  

  const generateExcel = () => {
    if (!reportData || !questionAnalysisData) {
      console.error('Report data or question analysis data is missing');
      return;
    }

    const workbook = XLSX.utils.book_new();

    const reportSummaryData = [
      ['Report Title', reportData.summary?.report_summary?.report_title || ''],
      ['Research Summary', reportData.summary?.report_summary?.research_summary || ''],
      [],
      ['Key Findings'],
      ['Finding Title', 'Percentage', 'Description', 'Representative Quotes'],
      ...(reportData.summary?.report_summary?.key_findings?.map(finding => [
        finding.finding_title || '',
        finding.percentage || '',
        finding.finding_description || '',
        (finding.representative_quotes || []).join('; ')
      ]) || []),
      [],
      ['Actionable Insights'],
      ...(reportData.summary?.report_summary?.actionable_insights?.map(insight => [insight]) || [])
    ];

    const reportSummarySheet = XLSX.utils.aoa_to_sheet(reportSummaryData);
    XLSX.utils.book_append_sheet(workbook, reportSummarySheet, 'Report Summary');

    const questionAnalysisSheetData = [
      ['Question', 'Theme', 'Percentage', 'Summary', 'Representative Quotes']
    ];

    Object.entries(questionAnalysisData).forEach(([questionKey, questionData]) => {
      if (questionData && Array.isArray(questionData.themes)) {
        questionData.themes.forEach(theme => {
          questionAnalysisSheetData.push([
            questionData.question_text || '',
            theme.theme || '',
            theme.percentage || '',
            theme.summary || '',
            (theme.representative_quotes || []).join('; ')
          ]);
        });
      }
    });

    const questionAnalysisSheet = XLSX.utils.aoa_to_sheet(questionAnalysisSheetData);
    XLSX.utils.book_append_sheet(workbook, questionAnalysisSheet, 'Question Analysis');

    XLSX.writeFile(workbook, `${reportData.summary?.report_summary?.report_title || 'Campaign Report'}.xlsx`);
  };

  const handleThemeClick = (questionKey, theme) => {
    // Update selected theme only for the current question
    setSelectedThemes((prevThemes) => ({
      ...prevThemes,
      [questionKey]: theme,
    }));
  
    // Hide arrows only for the bars of the current question
    document.querySelectorAll(`[data-question="${questionKey}"] .theme-bar-arrow`).forEach((arrow) => {
      arrow.style.display = 'none';
    });
  
    // Show the arrow for the selected theme within the current question
    const selectedBar = document.querySelector(`[data-question="${questionKey}"][data-theme="${theme}"] .theme-bar`);
    if (selectedBar) {
      const arrow = selectedBar.querySelector('.theme-bar-arrow');
      if (arrow) {
        const themeIndex = questionAnalysisData[questionKey].themes.findIndex((t) => t.theme === theme);
        arrow.style.display = 'block';
        arrow.style.borderLeftColor = themeColors[themeIndex % themeColors.length];
      }
    }
  };
  


  return (
    <div className='report-content'>
    <div className="builder-header">
      <div className="back-button-campaign-builder" onClick={handleBack}>
        <img src={cross} className='cross-icon' />
      </div>

      <div className='campaign-name-input'>Insights Report </div>
      <div className="button-container">

        <div onClick={generateExcel} className="download-button" disabled={loading || error}>
          <FontAwesomeIcon icon={faDownload} />
        </div>

        {projectType === "ai_moderated" && (
          <div className="mode-toggle" data-mode={mode} onClick={toggleMode}>
            <div className="mode-slider"></div>
            <div className="mode-text test">Test </div>
            <div className="mode-text live">Live </div>
          </div>
        )}

      </div>

    </div><div ref = {containerRef} className={`campaign-report-container ${drawerOpen ? 'drawer-open' : ''}`}>

       <div className="drawer-container">
        <div 
          ref={drawerRef} 
          className={`drawer ${drawerOpen ? 'expanded' : 'collapsed'}`}
          onMouseEnter={openDrawer}
        >

        <nav className="nav-menu">
          {navItems.map(({ id, label, icon }) => (
            <button
              key={id}
              className={`nav-item ${activeSection === id ? 'selected' : ''}`}
              onClick={() => scrollToSection(id)}
            >
              <FontAwesomeIcon icon={icon} className="nav-item-svg" />
              {showText && drawerOpen && <span>{label}</span>}
            </button>
          ))}
        </nav>

        </div>
      </div>
        <div className="campaign-report-content">

          {loading ? (
            <div className="loading-indicator-container">


              <div className="loading-indicator"></div>
            </div>
          )
            : error ? (
              <div className="no-data-message">{error}</div>
            )
              : ((!reportData || Object.keys(reportData).length === 0) && !loading) ? (
                <div className="no-data-message">No respondent has completed an interview yet, please check back again after some time.</div>
              ) : (


                <div className="report-body">

                  <h1>{reportData.summary?.report_summary?.report_title || 'Report Title'}</h1>
                  <h3> Number of respondents: {respondentCount}</h3>


                  <section ref={el => sectionRefs.current['research-summary'] = el} className="research-summary">
                    <h2><FontAwesomeIcon icon={faFileAlt} className="icon research-summary-icon" />  Research Summary</h2>
                    <p>{reportData.summary?.report_summary?.research_summary || 'Research Summary not available.'}</p>
                  </section>

                  {conceptTestData && concepts.length > 0 && attributes.length > 0 && (
                    <section className="concept-testing-insights">
                      <h2>
                        <FontAwesomeIcon icon={faFlask} className="icon concept-testing-icon" /> Concept Testing Insights
                      </h2>
                      <div className="concept-testing-table-wrapper">
                        <table className="concept-testing-table">


                          <thead>
                            <tr>
                              <th>Attributes</th>
                              {concepts.map((cId) => (
                                <th key={cId}>
                                  <div className="concept-media-container">
                                    {conceptDetails[cId]?.file_url && (
                                      <div
                                        className="concept-media"
                                        onClick={() => openFullScreenMedia(conceptDetails[cId].file_url)}
                                      >
                                        {conceptDetails[cId].file_url ? (
                                          <img
                                            src={conceptDetails[cId].file_url}
                                            alt={conceptDetails[cId].concept_name}
                                            className="concept-media-thumbnail" />
                                        ) : conceptDetails[cId].file_url.match(/\.(mp4|webm|mov)$/i) ? (
                                          <video
                                            src={conceptDetails[cId].file_url}
                                            className="concept-media-thumbnail"
                                            muted
                                            loop />
                                        ) : null}
                                      </div>
                                    )}
                                    <div className="concept-name">
                                      {conceptDetails[cId]?.concept_name.replace(/\.[^/.]+$/, '') || 'Loading...'}
                                    </div>

                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>


                          <tbody>
                            <tr className="impressions-row">
                              <td><strong>First Impressions</strong></td>
                              {concepts.map((cId) => (
                                <td key={`first-${cId}`} onClick={() => {
                                  setSelectedImpressionType('first_impressions');
                                  setSelectedSummary({
                                    conceptId: cId,
                                    summary: firstImpressionsLookup[cId],
                                    whyList: detailedFirstImpressions[cId] || []
                                  });
                                  setShowSidePanel(true);
                                } }>
                                  <span className="impression-summary-item">
                                    {firstImpressionsLookup[cId] ?? '-'}
                                    <FontAwesomeIcon icon={faFileAlt} className="transcript-icon" />
                                  </span>
                                </td>
                              ))}
                            </tr>

                            {Object.keys(comparativeImpressionsLookup).length > 0 && (
                              <tr className="impressions-row">
                                <td><strong>Comparative Impressions</strong></td>
                                {concepts.map((cId) => (
                                  <td key={`comp-${cId}`} onClick={() => {
                                    setSelectedImpressionType('comparative_impressions');
                                    setSelectedSummary({
                                      conceptId: cId,
                                      summary: comparativeImpressionsLookup[cId],
                                      whyList: detailedComparativeImpressions[cId] || []
                                    });
                                    setShowSidePanel(true);
                                  } }>
                                    <span className="impression-summary-item">
                                      {comparativeImpressionsLookup[cId] ?? '-'}
                                      <FontAwesomeIcon icon={faFileAlt} className="transcript-icon" />
                                    </span>
                                  </td>
                                ))}
                              </tr>
                            )}
                            {attributes.map((attrId) => (
                              <React.Fragment key={attrId}>
                                <tr
                                  className={`attribute-row ${expandedAttribute === attrId ? 'expanded' : ''}`}
                                  onClick={() => toggleAttribute(attrId)}
                                >
                                  <td>

                                    {`${attrId}`}
                                    <FontAwesomeIcon icon={faChevronRight} className="attribute-chevron" />
                                  </td>
                                  {concepts.map((cId) => {
                                    const avgRating = conceptTestData.details_why_aggregated_concept_vs_attribute_ratings_table[cId][attrId]?.avg_rating;
                                    const rating = avgRating ?? '-';

                                    if (rating === '-') {
                                      // If no rating, treat as red zone by default or just neutral style
                                      return (
                                        <td key={`${cId}-${attrId}`}>
                                          <span
                                            className="rating-chip red-zone"
                                            style={{ backgroundColor: '#f8d7da', border: '1px solid #f5c6cb' }}
                                          >
                                            -
                                          </span>
                                        </td>
                                      );
                                    } else {
                                      const numericRating = Number(rating);
                                      const style = getRatingStyle(numericRating);
                                      let zoneClass = '';
                                      if (numericRating <= 3) {
                                        zoneClass = 'red-zone';
                                      } else if (numericRating <= 7) {
                                        zoneClass = 'yellow-zone';
                                      } else {
                                        zoneClass = 'green-zone';
                                      }

                                      return (
                                        <td key={`${cId}-${attrId}`}>
                                          <span className={`rating-chip ${zoneClass}`} style={style}>
                                            {numericRating.toFixed(1)}
                                          </span>
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>
                                {expandedAttribute === attrId && (
                                  <tr className="attribute-summary-row">
                                    <td colSpan={concepts.length + 1}>
                                      <div className="attribute-summaries">
                                        {concepts.map((cId) => {
                                          const summaryItem = summaryLookup[`${cId}-${attrId}`];
                                          if (!summaryItem) return null;

                                          const numericRating = Number(summaryItem.avg_rating);
                                          const style = getRatingStyle(numericRating);
                                          let zoneClass = '';
                                          if (numericRating <= 3) {
                                            zoneClass = 'red-zone';
                                          } else if (numericRating <= 7) {
                                            zoneClass = 'yellow-zone';
                                          } else {
                                            zoneClass = 'green-zone';
                                          }

                                          return (
                                            <div
                                              key={cId}
                                              className="attribute-summary-item"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                openSidePanel(cId, attrId);
                                              } }
                                            >
                                              <h4>
                                                {conceptToNameMap[cId]} - {attrId}:
                                                <span className={`rating-chip ${zoneClass}`} style={{ ...style, marginLeft: '8px' }}>
                                                  {summaryItem.avg_rating.toFixed(1)}
                                                </span>
                                              </h4>
                                              <p>{summaryItem.rating_reason_summary}</p>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </section>
                  )}

                  {/* Side panel for details */}
                  {showSidePanel && selectedSummary && (
                    <div className="side-panel-overlay" onClick={closeSidePanel}>
                      <div className="side-panel" onClick={(e) => e.stopPropagation()}>
                        <button className="close-button" onClick={closeSidePanel}>X</button>

                        {conceptDetails[selectedSummary.conceptId]?.file_url && (
                          <div className="concept-media-container">
                            <div
                              className="concept-media"
                              onClick={() => openFullScreenMedia(conceptDetails[selectedSummary.conceptId].file_url)}
                            >
                              {conceptDetails[selectedSummary.conceptId].file_url ? (
                                <img
                                  src={conceptDetails[selectedSummary.conceptId].file_url}
                                  alt={conceptDetails[selectedSummary.conceptId]?.concept_name}
                                  className="concept-media-thumbnail" />
                              ) : conceptDetails[selectedSummary.conceptId].file_url.match(/\.(mp4|webm|mov)$/i) ? (
                                <video
                                  src={conceptDetails[selectedSummary.conceptId].file_url}
                                  className="concept-media-thumbnail"
                                  muted
                                  loop />
                              ) : null}
                            </div>
                            <div className="concept-name">
                              {conceptDetails[selectedSummary.conceptId]?.concept_name.replace(/\.[^/.]+$/, '') || 'Loading...'}
                            </div>
                          </div>
                        )}
                        <h3>
                          {selectedImpressionType === 'first_impressions' && 'First Impressions'}
                          {selectedImpressionType === 'comparative_impressions' && 'Comparative Impressions'}
                          {!selectedImpressionType && `Attribute ${selectedSummary.attributeId}`}
                        </h3>
                        {!selectedImpressionType && <h4>Avg Rating: {selectedSummary.avgRating}</h4>}


                        <p>{selectedSummary.summary}</p>
                        <div className="why-list">
                          <h5>User Responses</h5>
                          {selectedSummary.whyList?.map((reason, idx) => (
                            <div key={idx} className="why-item">
                              {reason}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* End of Concept Testing Section */}

                  <section ref={el => sectionRefs.current['key-findings'] = el} className="key-findings">
                    <h2><FontAwesomeIcon icon={faLightbulb} className="icon key-findings-icon" /> Key Findings</h2>
                    {reportData.summary?.report_summary?.key_findings?.map((finding, index) => (
                      <div key={index} className="key-finding">
                        <details open={index === 0}>
                          <summary>
                            <div className="finding-header">
                              <span className="theme">{finding.finding_title || 'No theme available'}</span>
                              <span className="summary">{finding.finding_description || 'No summary available'}</span>
                            </div>
                          </summary>
                          <div className="quote-container">
                            {finding.representative_quotes?.map((quote, i) => (
                              <blockquote key={i} className="quote">
                                {`"${quote}"`}
                              </blockquote>
                            ))}
                          </div>
                        </details>
                      </div>
                    ))}
                  </section>

                  <section ref={el => sectionRefs.current['recommendations'] = el} className="recommendations">

                    <h2><FontAwesomeIcon icon={faCheckCircle} className="icon recommendations-icon" />Recommendations</h2>
                    <ul>
                      {reportData.summary?.report_summary?.actionable_insights?.map((insight, index) => (
                        <li key={index}>{insight}</li>
                      ))}
                    </ul>
                  </section>

                  <section ref={el => sectionRefs.current['question-analysis'] = el} className="question-analysis">

                    <h2><FontAwesomeIcon icon={faLightbulb} className="icon question-analysis-icon" /> Question Level Analysis</h2>
                    {questionAnalysisData && Object.keys(questionAnalysisData).map((questionKey, index) => {
                      const questionData = questionAnalysisData[questionKey];
                      const selectedTheme = selectedThemes[questionKey];
                      const selectedThemeObject = questionData?.themes?.find(t => t.theme === selectedTheme);

                      // if (!questionData || !questionData.themes) {
                      //   return null;
                      // }

                      if (!questionData || !questionData.themes || !questionData.themes.length) {
                        // If it's empty, show the "unanswered" message
                        return (
                          <div key={index} className="question-analysis-item">
                            <h3>{questionData.question_text}</h3>
                            <div className="unanswered-message">
                              {respondentCount > 1
                                ? 'This question has been unanswered in all the interview conversations.'
                                : 'This question has been unanswered in the interview conversation.'}
                            </div>
                          </div>
                        );
                      }

                      return (
                        <div key={index} className="question-analysis-item">
                          <h3>{questionAnalysisData[questionKey].question_text}</h3>
                          <hr />
                          <div className="question-analysis-content">
                            <div className="question-analysis-themes">
                              {questionAnalysisData[questionKey].themes
                                .sort((a, b) => b.percentage - a.percentage)
                                .map((theme, themeIndex) => (
                                  <div key={themeIndex} className="theme-bar-wrapper">
                                    <div className="theme-text-container">
                                      <span className="theme-text">{theme.theme}</span>
                                    </div>
                                    <div
                                      className="theme-bar-container"
                                      data-question={questionKey}
                                      data-theme={theme.theme}
                                      onClick={() => handleThemeClick(questionKey, theme.theme)}
                                    >
                                      <div
                                        className="theme-bar"
                                        style={{
                                          width: `${theme.percentage}%`,
                                          backgroundColor: themeColors[themeIndex] // Assign color based on index
                                        }}
                                      >
                                        <div className='theme-percent'> {theme.percentage}% </div>
                                        <div className="theme-bar-arrow"></div>
                                      </div>
                                    </div>
                                  </div>
                                ))}

                            </div>
                            <div className="question-analysis-details">
                              {selectedThemeObject && (
                                <>
                                  <h4>{selectedThemeObject.theme}</h4>
                                  <p>{selectedThemeObject.summary}</p>
                                  <div className="quote-container">
                                    {selectedThemeObject.representative_quotes.map((quote, i) => (
                                      <blockquote key={i} className="quote">
                                        {`"${quote}"`}
                                      </blockquote>
                                    ))}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </section>



                  <section ref={el => sectionRefs.current['transcripts'] = el} className="transcripts">

                    <h2><FontAwesomeIcon icon={faFileAlt} className="icon transcripts-icon" /> Transcripts</h2>

                    {/* {selectedTranscript && audioUrl && (
                      <div className="audio-button-container">
                        <button
                          className="audio-button"
                          onClick={() => audioRef.current.play()} // Play the audio when clicked
                          disabled={audioLoading}
                        >
                          <FontAwesomeIcon icon={faPlay} /> Play Audio
                        </button>
                      </div>
                    )} */}

                    {transcriptLoading ? (
                      <div>
                        <section className="transcripts-flex-container">
                          <div className="transcript-loading">
                            <div className="loading-indicator"></div>
                          </div>
                        </section>


                      </div> // Show loading only for transcripts
                    ) : error ? (
                      <div>{error}</div> // Show error for transcripts
                    ) : (
                      <div>

                        <section className="transcripts-flex-container">

                          <section className="transcripts-table-container">
                            <table className="transcripts-table">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  {transcripts.length > 0 &&
                                    transcripts[0].identification_fields?.fields?.map((field, index) => field && field !== 'none' ? <th key={index}>{field}</th> : null
                                    )}
                                </tr>
                              </thead>
                              <tbody>
                                {transcripts.map((transcript, rowIndex) => (
                                  <tr
                                    key={transcript.id}
                                    className={`transcript-item ${selectedTranscript && selectedTranscript.id === transcript.id
                                        ? 'selected'
                                        : ''}`}
                                    onClick={() => handleTranscriptClick(transcript)}
                                  >
                                    <td>{formatDate(transcript.created_at)}</td>
                                    {transcript.identification_fields?.fields?.map((field, cellIndex) => field && field !== 'none' ? (
                                      <td key={cellIndex}>
                                        {transcript.identification_values[field]
                                          ? transcript.identification_values[field].trim()
                                          : 'N/A'}
                                      </td>
                                    ) : null // Render cell only if field is not null or 'none'
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </section>


                          {/* The Transcript Content (right side) */}
                          <section className="transcript-content">
                            {audioUrl && (
                              <div className="audio-player-container">
                                <audio controls ref={audioRef} src={audioUrl}>
                                  Your browser does not support the audio element.
                                </audio>
                              </div>
                            )}
                            {selectedTranscript ? (
                              selectedTranscript.written_transcript ? (
                                <div className="transcript-dialogue">
                                  {formatTranscript(selectedTranscript.written_transcript)}
                                </div>
                              ) : (
                                <p>No transcript available for this as it has not been submitted for analysis yet.</p>
                              )
                            ) : (
                              <p>Select a transcript to view its content</p>
                            )}
                          </section>


                        </section>

                      </div>
                    )}

                    {/* Pagination Controls */}
                    {totalPages > 1 && (
                      <section className="pagination">
                        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                          <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                      </section>
                    )}
                  </section>

                  <section ref={el => sectionRefs.current['affinity-mapping'] = el} className="affinity-mapping">

                    <h2><FontAwesomeIcon icon={faObjectGroup} className="icon transcripts-icon" /> Affinity Mapping</h2>
                    <DndProvider backend={HTML5Backend}>
                      <AffinityCanvas codes={reportData.codes} />
                    </DndProvider>
                  </section>


                </div>
              )}
        </div>
        {fullScreenMedia && (
          <div className="full-screen-overlay" onClick={closeFullScreenMedia}>
            <div className="full-screen-media-container" onClick={(e) => e.stopPropagation()}>
              {fullScreenMedia.match(/\.(jpeg|jpg|png|gif)$/i) ? (
                <img src={fullScreenMedia} alt="Full Screen Media" className="full-screen-media" />
              ) : fullScreenMedia.match(/\.(mp4|webm)$/i) ? (
                <video
                  src={fullScreenMedia}
                  className="full-screen-media"
                  controls
                  autoPlay />
              ) : null}
              <button className="close-button" onClick={closeFullScreenMedia}>
                Close
              </button>
            </div>
          </div>
        )}

      </div>
      </div>
  );
};

const adjustColorBrightness = (percentage, minFactor = 0.5, maxFactor = 1.5) => {
  const baseColor = { r: 129, g: 90, b: 240 }; // RGB for #815AF0
  const white = { r: 255, g: 255, b: 255 }; // RGB for #FFFFFF
  
  const factor = minFactor + (maxFactor - minFactor) * (percentage / 100);

  const adjustedColor = {
    r: Math.round(baseColor.r + (white.r - baseColor.r) * (1 - factor)),
    g: Math.round(baseColor.g + (white.g - baseColor.g) * (1 - factor)),
    b: Math.round(baseColor.b + (white.b - baseColor.b) * (1 - factor)),
  };

  return `rgb(${adjustedColor.r}, ${adjustedColor.g}, ${adjustedColor.b})`;
};

export default CampaignReport;
