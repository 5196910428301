import React, { useState, useRef, useEffect, useMemo, forwardRef, useImperativeHandle } from 'react';
import './CampaignBuilder.css';
import { FaSave, FaEdit, FaTrashAlt, FaArrowLeft, FaCross } from 'react-icons/fa'; 
import cross from '../assets/cross.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { ThreeDots } from 'react-loader-spinner';
import mixpanel, { identify } from 'mixpanel-browser';
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import ResearchTypeStep from './CampaignBuilderSteps/ResearchTypeStep';
import CreativeOptionsStep from './CampaignBuilderSteps/CreativeOptionsStep';
import ObjectiveStep from './CampaignBuilderSteps/ObjectiveStep';
import DiscussionGuideStep from './CampaignBuilderSteps/DiscussionGuideStep';
import WhoStep from './CampaignBuilderSteps/WhoStep';
import ReviewStep from './CampaignBuilderSteps/ReviewStep';
import TestStep from './CampaignBuilderSteps/TestStep';
import IdentifyStep from './CampaignBuilderSteps/IdentifyStep';
import RecruitmentCriteriaBottomSheetStep from './CampaignBuilderSteps/RecruitmentCriteriaBottomSheetStep'; 
import ScreenerQuestionsBottomSheetStep from './CampaignBuilderSteps/ScreenerBottomSheetStep';
import PostLiveStep from './CampaignBuilderSteps/PostLiveStep';
import UploadCreativesStep from './CampaignBuilderSteps/UploadCreativesStep';
import * as ApiUtils from './ApiUtils';
import * as ConstantUtils from './ConstantUtils';
import ProgressContainer from './ProgressContainer'; 

mixpanel.init('1702428ef9acdb5aa84df81256d5fe13', { debug: false, track_pageview: false, persistence: 'localStorage' });


const CampaignBuilder = ({showPostLiveStep}) => {

    const [searchParams] = useSearchParams();
    const initialStepParam = searchParams.get('step');
    const initialStep = initialStepParam !== null ? parseInt(initialStepParam, 10) : null;
    const navigate = useNavigate();
    const location = useLocation();
    const [campaign, setCampaign] = useState(null);
    const [step, setStep] = useState(initialStep !== null ? initialStep : 0);

    const [selectedBlock, setSelectedBlock] = useState(null); 
    
    const handleBackClick = () => {
      navigate(-1); 
    };
    
    const [editIndex, setEditIndex] = useState(null); 

    const [loading, setLoading] = useState(false); 
    const [newQuestion, setNewQuestion] = useState('');
    const [audienceOption, setAudienceOption] = useState(null); 
    const [isGeneratingDiscussionGuide, setIsGeneratingDiscussionGuide] = useState(false);

    const [researchObjective, setResearchObjective] = useState(campaign ? campaign.business_objective : '');
    
    const [conceptDescription, setConceptDescription] = useState(campaign ? campaign.context : '');
    const questionsGeneratedRef = useRef(false);
    const [selectedCreativeType, setSelectedCreativeType] = useState(null);
    const [selectedCreativeQuantity, setSelectedCreativeQuantity] = useState(null);
    const [selectedCreativeTestMethod, setSelectedCreativeTestMethod] = useState(null);
    const [initialCreativeType, setInitialCreativeType] = useState(null);
    const [initialCreativeQuantity, setInitialCreativeQuantity] = useState(null);
    const [initialCreativeTestMethod, setInitialCreativeTestMethod] = useState(null);
    const [creatives, setCreatives] = useState([]);
    const [discussionGuide, setDiscussionGuide] = useState([]);

    const countryOptions = ConstantUtils.countryOptions;
    const stateOptions = ConstantUtils.stateOptions;
    const educationOptions = ConstantUtils.educationOptions;

    const occupationOptions = ConstantUtils.occupationOptions;
    const incomeRangeOptions = ConstantUtils.incomeRangeOptions;

    const [campaignName, setCampaignName] = useState(campaign ? campaign.name : '');
    const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const [initialObjective, setInitialObjective] = useState('');
    const [initialAttributeIds, setInitialAttributeIds] = useState([]);
    const [initialDiscussionGuide, setInitialDiscussionGuide] = useState([]); 
    const [testLink, setTestLink] = useState(campaign ? campaign.test_link : null);
    const [liveLink, setLiveLink] = useState(campaign ? campaign.live_link : null);
    const [initialContext, setInitialContext] = useState('');
    const [navigatedBack, setNavigatedBack] = useState(false);
    const researchObjectiveRef = useRef(null);
    const conceptDescriptionRef = useRef(null);
    const [identificationMethod, setIdentificationMethod] = useState('none');
    const [customFieldInputVisible, setCustomFieldInputVisible] = useState(false); 
    const [fullLoading, setFullLoading] = useState(false); 
    const [progress, setProgress] = useState(0);
    const [progressStatus, setProgressStatus] = useState("");
    
    
    const [selectedTopicId, setSelectedTopicId] = useState(
      discussionGuide ? discussionGuide[0]?.topic_id : null
    );
    const [suggestedFieldsForLink, setSuggestedFieldsForLink] = useState(["name", "email", "user_id"]); 
    const [suggestedFieldsForAsk, setSuggestedFieldsForAsk] = useState(["Name", "Email", "User ID"]); 

    const [selectedFieldsForLink, setSelectedFieldsForLink] = useState([]); 
    const [selectedFieldsForAsk, setSelectedFieldsForAsk] = useState([]); 
    
    const [minAge, setMinAge] = useState('');
    const [maxAge, setMaxAge] = useState('');
    const criteriaOptions = ConstantUtils.criteriaOptions;
    const [availableCriteria, setAvailableCriteria] = useState(criteriaOptions); 
    const [savedRecruitmentCriteria, setSavedRecruitmentCriteria] = useState({
      countries: [],
      state: [],
      age: { min: null, max: null },
      education: [],
      occupation: [],
      incomeRange: [],
      gender: null,
      language: []
    });
    
    const [savedScreenerQuestions, setSavedScreenerQuestions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState([]); 
    const [selectedState, setSelectedState] = useState([]); 
    const [selectedEducation, setSelectedEducation] = useState([]); 
    const [selectedOccupation, setSelectedOccupation] = useState([]); 
    const [selectedIncomeRange, setSelectedIncomeRange] = useState([]); 
    
    const [selectedGender, setSelectedGender] = useState(null); 
    const [selectedLanguage, setSelectedLanguage] = useState([]); 
    const [screenerQuestions, setScreenerQuestions] = useState([]); 
    const [numberOfParticipants, setNumberOfParticipants] = useState(0);
    const [incentiveAmount, setIncentiveAmount] = useState(0.0);
    const [externalProjectTitle, setExternalProjectTitle] = useState('');
    const [externalProjectDescription, setExternalProjectDescription] = useState('');
    const [attributes, setAttributes] = useState([]);
    const [attributeIds, setAttributeIds] = useState([]);
    
    const [customField, setCustomField] = useState(""); 
    const { id } = useParams();
    const [campaignId, setCampaignId] = useState(id || null); 

    const [validationErrors, setValidationErrors] = useState({
      participants: "",
      incentive: "",
      country: "",
      screenerQuestions: "",
      externalProjectTitle:"",
      externalProjectDescription:"",
      uploadCreatives: "", 
      creativeType:"",
      creativeQuantity:"",
      creativeTestMethod:"",
      discussionGuideErrors: {},

    });
    
    const genderOptions = ConstantUtils.genderOptions;
    const languageOptions = ConstantUtils.languageOptions
    const multipleChoiceActionOptions = ConstantUtils.multipleChoiceActionOptions
    const singleChoiceActionOptions = ConstantUtils.singleChoiceActionOptions
    const questionTypeOptions = ConstantUtils.questionTypeOptions

    const [targetAudience, setTargetAudience] = useState(null);
    const [isCustomField, setIsCustomField] = useState({});
    const [showRecruitmentSheet, setShowRecruitmentSheet] = useState(false);
    const [showScreenerSheet, setShowScreenerSheet] = useState(false);
    const [selectedCriteria, setSelectedCriteria] = useState([]);

  
    const selectedCountryRef = useRef(selectedCountry);
    const selectedStateRef = useRef(selectedState);
    const selectedEducationRef = useRef(selectedEducation);
    const selectedOccupationRef = useRef(selectedOccupation);
    const selectedIncomeRangeRef = useRef(selectedIncomeRange);
    const minAgeRef = useRef(minAge);
    const maxAgeRef = useRef(maxAge);
    const selectedGenderRef = useRef(selectedGender);
    const selectedLanguageRef = useRef(selectedLanguage);
    const numberOfParticipantsRef = useRef(numberOfParticipants);
    const incentiveAmountRef = useRef(incentiveAmount);
    const externalProjectTitleRef = useRef(externalProjectTitle);
    const externalProjectDescriptionRef = useRef(externalProjectDescription);
    const screenerQuestionsRef = useRef(screenerQuestions);

    const clearValidationError = (field) => {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "", 
      }));
    };
    
    
    useEffect(() => {
      selectedCountryRef.current = selectedCountry;
    }, [selectedCountry]);

    useEffect(() => {
      selectedStateRef.current = selectedState;
    }, [selectedState]);

    useEffect(() => {
      selectedEducationRef.current = selectedEducation;
    }, [selectedEducation]);

    useEffect(() => {
      selectedOccupationRef.current = selectedOccupation;
    }, [selectedOccupation]);

    useEffect(() => {
      selectedIncomeRangeRef.current = selectedIncomeRange;
    }, [selectedIncomeRange]);

    useEffect(() => {
      minAgeRef.current = minAge;
      maxAgeRef.current = maxAge;
    }, [minAge, maxAge]);

    useEffect(() => {
      selectedGenderRef.current = selectedGender;
    }, [selectedGender]);

    useEffect(() => {
      selectedLanguageRef.current = selectedLanguage;
    }, [selectedLanguage]);

    useEffect(() => {
      numberOfParticipantsRef.current = numberOfParticipants;
    }, [numberOfParticipants]);

    useEffect(() => {
      incentiveAmountRef.current = incentiveAmount;
    }, [incentiveAmount]);

    useEffect(() => {
      externalProjectTitleRef.current = externalProjectTitle;
    }, [externalProjectTitle]);

    useEffect(() => {
      externalProjectDescriptionRef.current = externalProjectDescription;
    }, [externalProjectDescription]);

    useEffect(() => {
      screenerQuestionsRef.current = screenerQuestions;
    }, [screenerQuestions]);


    
  const addNewQuestion = (questionType) => {
    setScreenerQuestions([
      ...screenerQuestions,
      {
        id: `question-${screenerQuestions.length + 1}`,
        questionText: '',
        questionType: questionType,
        options: ['short', 'long'].includes(questionType) ? [] : [{ text: '', action: 'none' }],
        required: false,
        autoAccept: [],
        autoReject: [],
      },
    ]);
  };

  const handleAddOption = (questionId, optionIndex) => {
    setScreenerQuestions(prevQuestions =>
      prevQuestions.map(q =>
        q.id === questionId
          ? {
              ...q,
              options: [
                ...q.options.slice(0, optionIndex + 1),
                { text: '', action: 'none' },
                ...q.options.slice(optionIndex + 1),
              ],
            }
          : q
      )
    );
  };

    
  const handleRemoveOption = (questionId, optionIndex) => {
    setScreenerQuestions(prevQuestions =>
      prevQuestions.map(q => {
        if (q.id === questionId) {
          const updatedOptions = [...q.options];
          updatedOptions.splice(optionIndex, 1); // Remove the selected option

          return { ...q, options: updatedOptions };
        }
        return q;
      })
    );
  };


    const handleToggleRequired = (questionId) => {
      const updatedQuestions = screenerQuestions.map((q) =>
        q.id === questionId ? { ...q, required: !q.required } : q
      );
      setScreenerQuestions(updatedQuestions);
    };


    const applyRecruitmentSettings = (campaign) => {
      if (campaign.audience_option === "recruit") {
        setAudienceOption("recruit");
    
        setNumberOfParticipants(campaign.target_audience.number_of_participants || 0);
        setIncentiveAmount(campaign.target_audience.incentive_amount || 0.0);
    
        setSelectedCountry(
          (campaign.target_audience.countries || []).map(countryValue => {
            return countryOptions.find(option => option.value === countryValue) || { value: countryValue, label: countryValue };
          })
        );
    
        setSelectedState(
          (campaign.target_audience.state || []).map(stateValue => {
            return stateOptions.find(option => option.value === stateValue) || { value: stateValue, label: stateValue };
          })
        );
    
        setSelectedEducation(
          (campaign.target_audience.education || []).map(educationValue => {
            return educationOptions.find(option => option.value === educationValue) || { value: educationValue, label: educationValue };
          })
        );
    
        setSelectedOccupation(
          (campaign.target_audience.occupation || []).map(occupationValue => {
            return occupationOptions.find(option => option.value === occupationValue) || { value: occupationValue, label: occupationValue };
          })
        );
    
        setSelectedIncomeRange(
          (campaign.target_audience.income_range || []).map(incomeValue => {
            return incomeRangeOptions.find(option => option.value === incomeValue) || { value: incomeValue, label: incomeValue };
          })
        );
        setExternalProjectTitle(campaign.target_audience.external_title || '');
        setExternalProjectDescription(campaign.target_audience.external_description || '');

    
        setMinAge(campaign.target_audience.age_min || '');
        setMaxAge(campaign.target_audience.age_max || '');
    
        setSelectedGender(
          campaign.target_audience.gender
            ? genderOptions.find(option => option.value === campaign.target_audience.gender) || { value: campaign.target_audience.gender, label: campaign.target_audience.gender }
            : null
        );
    
        setSelectedLanguage(
          (campaign.target_audience.languages || []).map(languageValue => {
            return languageOptions.find(option => option.value === languageValue) || { value: languageValue, label: languageValue };
          })
        );
    
        const recruitmentCriteria = {
          countries: (campaign.target_audience.countries || []).map(countryValue => {
            return countryOptions.find(option => option.value === countryValue)?.label || countryValue;
          }),
          state: (campaign.target_audience.state || []).map(stateValue => {
            return stateOptions.find(option => option.value === stateValue)?.label || stateValue;
          }),
          age: { min: campaign.target_audience.age_min, max: campaign.target_audience.age_max },
          education: (campaign.target_audience.education || []).map(educationValue => {
            return educationOptions.find(option => option.value === educationValue)?.label || educationValue;
          }),
          occupation: (campaign.target_audience.occupation || []).map(occupationValue => {
            return occupationOptions.find(option => option.value === occupationValue)?.label || occupationValue;
          }),
          incomeRange: (campaign.target_audience.income_range || []).map(incomeValue => {
            return incomeRangeOptions.find(option => option.value === incomeValue)?.label || incomeValue;
          }),
          gender: campaign.target_audience.gender
            ? genderOptions.find(option => option.value === campaign.target_audience.gender)?.label || campaign.target_audience.gender
            : null,
          language: (campaign.target_audience.languages || []).map(languageValue => {
            return languageOptions.find(option => option.value === languageValue)?.label || languageValue;
          }),
        };
    
        setSavedRecruitmentCriteria(recruitmentCriteria);
    
        let criteriaList = [];
    
        if ((campaign.target_audience.countries || []).length > 0) criteriaList.push('Country');
        if ((campaign.target_audience.state || []).length > 0) criteriaList.push('State');
        if (campaign.target_audience.age_min || campaign.target_audience.age_max) criteriaList.push('Age');
        if ((campaign.target_audience.education || []).length > 0) criteriaList.push('Education Level');
        if ((campaign.target_audience.occupation || []).length > 0) criteriaList.push('Occupation');
        if ((campaign.target_audience.income_range || []).length > 0) criteriaList.push('Household Income');
        if (campaign.target_audience.gender) criteriaList.push('Gender');
        if ((campaign.target_audience.languages || []).length > 0) criteriaList.push('Language');
    
        setSelectedCriteria(criteriaList);
        setAvailableCriteria(criteriaOptions.filter(option => !criteriaList.includes(option)));
    
        if (campaign.target_audience.screener_questions) {
          const screenerQuestionsData = campaign.target_audience.screener_questions.map((q, index) => ({
            id: `question-${index + 1}`,
            questionText: q.question_text,
            questionType: q.question_type,
            options: q.options || [],
            required: q.required || false,
          }));
          setScreenerQuestions(screenerQuestionsData);
          setSavedScreenerQuestions(screenerQuestionsData);
        }
      }
    };

    

  const handleCriteriaSelect = (option) => {
    if (!selectedCriteria.includes(option)) {
      setAvailableCriteria(availableCriteria.filter((item) => item !== option));
      setSelectedCriteria([...selectedCriteria, option]);
    }
  };




  const handleAddField = (field, isFromSuggested = false, optionType = 'link') => {
    
      setIsCustomField({
        ...isCustomField,
        [field]: !isFromSuggested, 
      });

      if (isFromSuggested) {
        if (optionType === 'link') {
          setSelectedFieldsForLink([...selectedFieldsForLink, field]);
          setSuggestedFieldsForLink(suggestedFieldsForLink.filter(suggestedField => suggestedField !== field));
        } else if (optionType === 'info') {
          setSelectedFieldsForAsk([...selectedFieldsForAsk, field]);
          setSuggestedFieldsForAsk(suggestedFieldsForAsk.filter(suggestedField => suggestedField !== field));
        }
      }
    
  };


  const handleRemoveField = (field, optionType = 'link') => { 

    if (!isCustomField[field]) {

      if (optionType === 'link') {
        setSelectedFieldsForLink(selectedFieldsForLink.filter(selectedField => selectedField !== field));

        setSuggestedFieldsForLink([...suggestedFieldsForLink, field]);
      } else if (optionType === 'info') {

        setSelectedFieldsForAsk(selectedFieldsForAsk.filter(selectedField => selectedField !== field));
        setSuggestedFieldsForAsk([...suggestedFieldsForAsk, field]);
      }
    }
    else {
      if (optionType === 'link') {
        setSelectedFieldsForLink(selectedFieldsForLink.filter(selectedField => selectedField !== field));
      } else if (optionType === 'info') {
        setSelectedFieldsForAsk(selectedFieldsForAsk.filter(selectedField => selectedField !== field));
      }

    }

    const updatedCustomFieldStatus = { ...isCustomField };
    delete updatedCustomFieldStatus[field];
    setIsCustomField(updatedCustomFieldStatus);
  };


  const handleCriteriaDeselect = (option) => {

    setSelectedCriteria(selectedCriteria.filter((item) => item !== option));

    setAvailableCriteria([...availableCriteria, option]);

    switch (option) {
      case 'Country':
        setSelectedCountry([]); 
        break;
      case 'State':
        setSelectedState([]); 
        break;
      case 'Age':
        setMinAge(''); 
        setMaxAge(''); 
        break;
      case 'Education Level':
        setSelectedEducation([]); 
        break;
      case 'Occupation':
        setSelectedOccupation([]); 
        break;
      case 'Household Income':
        setSelectedIncomeRange([]); 
        break;
      case 'Gender':
        setSelectedGender(null); 
        break;
      case 'Language':
        setSelectedLanguage([]); 
        break;
      default:
        break;
    }
  };




  const handleAddCustomField = (optionType) => {
    if (customField.trim()) {
      let fieldToAdd = customField.trim();

      if (optionType === "link") {
        fieldToAdd = fieldToAdd.toLowerCase().replace(/\s+/g, '_');
      }

      if (optionType === "link") {
        if (!selectedFieldsForLink.includes(fieldToAdd)) {
          setSelectedFieldsForLink([...selectedFieldsForLink, fieldToAdd]);
        }
      } else {
        if (!selectedFieldsForAsk.includes(fieldToAdd)) {
          setSelectedFieldsForAsk([...selectedFieldsForAsk, fieldToAdd]);
        }
      }

      setIsCustomField({
        ...isCustomField,
        [fieldToAdd]: true, 
      });

      setCustomField(""); 
    }
  };

  const removeDiscussionGuideError = (topicId, field) => {
    setValidationErrors(prev => {
      const guideErrors = { ...prev.discussionGuideErrors };
  
      // If there's no error for this topic, do nothing
      if (!guideErrors[topicId]) return prev;
  
      // Remove the specific field error
      delete guideErrors[topicId][field];
  
      // If that topic's error object is now empty, remove that topic from the errors
      if (Object.keys(guideErrors[topicId]).length === 0) {
        delete guideErrors[topicId];
      }
  
      return {
        ...prev,
        discussionGuideErrors: guideErrors
      };
    });
  };

  const generateCampaignDiscussionGuide = async (instruction) => {
    try {
  
      const payload = instruction ? { instruction } : {};
      const discussionGuide = ApiUtils.generateCampaignDiscussionGuide({campaignId: campaignId, getAccessTokenSilently, getIdTokenClaims, instruction: payload})
  
      return discussionGuide;
    } catch (error) {
      console.error('Error generating questions:', error);
      throw error;
    }
  };
  

  const continueStep = async () => {

      
    const currentState = {
      researchObjective,
      campaignName,
      step,
      audienceOption,
      targetAudience,
      selectedFieldsForLink,
      selectedFieldsForAsk,
      identificationMethod,
      testLink,
      liveLink,
      campaignId,
      getAccessTokenSilently,
      getIdTokenClaims,
      selectedCreativeType,          
      selectedCreativeQuantity,     
      selectedCreativeTestMethod,
      selectedBlock,
      attributes,
      discussionGuide

    };

    setLoading(true);
    setNavigatedBack(false);

    if (step === steps.findIndex(s => s.name === 'Concept Type')) {
      if (!validateCreativeType()) {
        setLoading(false);
        return;
      }
    }

    if (step === steps.findIndex((s) => s.name === "Discussion Guide")) {
      const updatedDG = validateDiscussionGuide();
      if (!updatedDG) {
        // It's invalid: stop
        setLoading(false);
        return;
      }
      // It's valid => pass updatedDG directly to the save function
      currentState.discussionGuide = updatedDG;
    }
    
  

    if (step === steps.findIndex(s => s.name === 'Upload Concepts')) {
      if (creatives.length < 1) {
        if (selectedCreativeQuantity === '1') {
        setValidationErrors(prevErrors => ({
          ...prevErrors,
          uploadCreatives: 'Please upload a concept before continuing',
        }));
        setLoading(false);
        return;
      }
      else {
        setValidationErrors(prevErrors => ({
          ...prevErrors,
          uploadCreatives: 'Please upload atleast 2 concepts before continuing',
        }));
        setLoading(false);
        return;
      }
      }
      else if (selectedCreativeQuantity === 'all' && creatives.length <= 1) {
        setValidationErrors(prevErrors => ({
          ...prevErrors,
          uploadCreatives: 'You selected "all" but uploaded only one creative. Please upload more creatives to proceed.',
        }));
        setLoading(false);
        return;
      } else {
        setValidationErrors(prevErrors => ({
          ...prevErrors,
          uploadCreatives: '',
        }));
      }
    }


  
    const isObjectiveStep = (step, selectedBlock, audienceOption) => 
      isStepOfType(step, 'objective', selectedBlock, audienceOption);

  
    if (isObjectiveStep (step, selectedBlock, audienceOption) && !validateResearchObjective(researchObjective)) {
      setLoading(false);
      return;
    }

    const isWhoStep = (step, selectedBlock, audienceOption) => 
      isStepOfType(step, 'who', selectedBlock, audienceOption);
  
    if (isWhoStep && audienceOption === 'recruit' && !validateRecruitmentSection()) {
      setLoading(false);
      return;
    }

  
    try {

      const saveFunction = saveFunctions[step];
      
      if (saveFunction) {
        await saveFunction(currentState);
      }


      if (
        step === generateDiscussionGuideStepIndex &&
        !navigatedBack &&
        checkAndGenerateDiscussionGuide
      ) {
        checkAndGenerateDiscussionGuide(); // Start questions generation in the background
      }


      if (steps[step].type === 'discussion_guide') {

        if (!testLink) {
          const testLink = await ApiUtils.createInterview( {campaignId, getAccessTokenSilently, getIdTokenClaims, interviewType:'test'});
          setTestLink(testLink.id);
        }

        
      }
      if (step === steps.length - 1) {
        if (!liveLink) {
          const liveLink = await ApiUtils.createInterview({campaignId, getAccessTokenSilently, getIdTokenClaims, interviewType:'live'});
          setLiveLink(liveLink.id);
        }
  
        handleBackClick();
      } 
      else {
        
        setStep(step + 1);
      }
  

    } catch (error) {
      console.error('Error in continue step:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleQuestionTextChange = (questionId, newText) => {
    setScreenerQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId ? { ...q, questionText: newText } : q
      )
    );
  };

  const saveCurrentStep = async () => {

    const currentState = {
      researchObjective,
      campaignName,
      step,
      audienceOption,
      targetAudience,
      selectedFieldsForLink,
      selectedFieldsForAsk,
      identificationMethod,
      testLink,
      liveLink,
      campaignId,
      getAccessTokenSilently,
      getIdTokenClaims,
      selectedCreativeType,          
      selectedCreativeQuantity,     
      selectedCreativeTestMethod,
      selectedBlock,
      attributes,
      discussionGuide
    };
    const saveFunction = saveFunctions[step];
    if (saveFunction) {
      await saveFunction(currentState);
    }
  };
  
  const saveResearchTypeStep = async () => {
    
    const researchType = selectedBlock
      ? ['consumer_research', 'ad_testing', 'concept_testing', 'consumer_feedback'][selectedBlock - 1]
      : null;
    const campaignData = {
      name: campaignName,
      type: researchType,
      current_step: step,
    };
  
    if (campaignId) {
      await updateCampaign(campaignData);
    } else {
      console.log("No campaign id");
    }
    setAudienceOption(audienceOption? audienceOption:'own');
  };


  
  const saveCreativeTypeStep = async (state) => {
    try {
      const creativeTypeData = {
        creative_type: state.selectedCreativeType,
        creatives_test_per_participant: state.selectedCreativeQuantity,
        test_display_method: state.selectedCreativeQuantity === 'all' ? state.selectedCreativeTestMethod : null,
      };
  
      // Call API to update the campaign with the creative type data
      await updateCampaign({
        ...creativeTypeData,
        name: state.campaignName,
        current_step: state.step,
      });
  
    } catch (error) {
      console.error('Error saving concept type step:', error);
    }
  };
  

  const saveUploadCreativesStep = async () => {
    // Save upload creative type fields and update
  };
  
  const saveObjectiveStep = async (state) => {
    const campaignData = {
      business_objective: state.researchObjective,
      name: state.campaignName,
      current_step: state.step,
    };
  
    if (state.selectedBlock === 3) {
      
      const attributeIds = state.attributes
        .filter(attribute => attribute.checked) 
        .map(attribute => attribute.id); 
  
      campaignData.attribute_ids = attributeIds.length > 0 ? attributeIds : null;
    }

  
    try {


      await updateCampaign(campaignData);
    } catch (error) {
      console.error('Error saving objective step:', error);
      throw error; 
    }
  };
  
  
  const saveDiscussionGuideStep = async (state) => {
    try {

      const updatedDiscussionGuide = state.discussionGuide;
      const updated = await ApiUtils.updateDiscussionGuide({
          campaignId: state.campaignId,
          discussionGuide: updatedDiscussionGuide,
          getAccessTokenSilently: state.getAccessTokenSilently,
          getIdTokenClaims: state.getIdTokenClaims,
      });
    
      
    } catch (error) {
      console.error("Error saving questions step:", error);
      
    }
  };
  

  const saveWhoStep = async (state) => {
    
    const targetData = getTargetData(state);
    const hasRecruitmentDataChanged = JSON.stringify(targetAudience) !== JSON.stringify(targetData);

    if (hasRecruitmentDataChanged) {
      const campaignData = {
        audience_option: state.audienceOption,
        target_audience: targetData,
        name: state.campaignName,
        current_step: state.step,
      };
     
      await updateCampaign(campaignData);
  };
}

  const saveIdentifyStep = async (state) => {
    if (state.audienceOption === 'own') {
      let identificationFields = {};
  
      if (state.identificationMethod === 'link') {
        identificationFields = { fields: state.selectedFieldsForLink };
      } else if (state.identificationMethod === 'ask') {
        identificationFields = { fields: state.selectedFieldsForAsk };
      }
  
      const campaignData = {
        identification_method: state.identificationMethod,
        identification_fields: identificationFields,
        current_step: state.step,
        name: state.campaignName,
      };
      await updateCampaign(campaignData);
    }
  };


  const saveReviewStep = async (state) => {
    if(state.campaignName != campaign.name) {
      const campaignData = {
        name: state.campaignName,
      };
      await updateCampaign(campaignData);

    }
    
  };


  const getTargetData = () => {
    return audienceOption === 'recruit' ? {
      countries: selectedCountryRef.current.map(c => c.value),
      state: selectedStateRef.current.map(s => s.value),
      education: selectedEducationRef.current.map(e => e.value),
      occupation: selectedOccupationRef.current.map(o => o.value),
      income_range: selectedIncomeRangeRef.current.map(i => i.value),
      age_min: minAgeRef.current || null,
      age_max: maxAgeRef.current || null,
      gender: selectedGenderRef.current ? selectedGenderRef.current.value : null,
      languages: selectedLanguageRef.current.map(l => l.value),
      number_of_participants: numberOfParticipantsRef.current,
      incentive_amount: incentiveAmountRef.current,
      external_title: externalProjectTitleRef.current,
      external_description: externalProjectDescriptionRef.current,
      screener_questions: screenerQuestionsRef.current.map(question => ({
        question_text: question.questionText,
        question_type: question.questionType,
        required: question.required,
        options: question.questionType === 'multiple' || question.questionType === 'single'
          ? question.options.map(option => ({
              text: option.text,
              action: option.action
            }))
          : ['short', 'long'].includes(question.questionType)
            ? [{ text: question.answerText || '' }]
            : []
      })),
    } : null;
  };


  const handleCreativeUpload = () => {
    setValidationErrors(prevErrors => ({
      ...prevErrors,
      uploadCreatives: '', 
    }));
  };
  

  const getStepConfig = (selectedBlock, audienceOption) => {
    const baseSteps = [{ name: 'Type', type: 'research'}];

    const baseComponents = [ResearchTypeStep];
    const baseSaveFunctions = [saveResearchTypeStep];
    
    if (selectedBlock === 3) {
    baseSteps.push(
      { name: 'Concept Type', type: 'creative'},
      { name: 'Upload Concepts',type: 'upload'}
    );
      baseComponents.push(CreativeOptionsStep);
      baseComponents.push(UploadCreativesStep);
      baseSaveFunctions.push((state) => saveCreativeTypeStep(state));
      baseSaveFunctions.push((state) => saveUploadCreativesStep(state));
    }
    
    baseSteps.push(
      { name: 'Objective', type: 'objective'},
      { name: 'Guide', type: 'discussion_guide'},
      { name: 'Test', type: 'test'},
      { name: 'Target', type: 'who'},
    );

    if (audienceOption === 'own') {
      baseSteps.push({ name: 'Identify', type: 'identify'});
    }

    baseSteps.push({ name: 'Review', type: 'review' });

    baseComponents.push(
      ObjectiveStep,
      DiscussionGuideStep, 
      TestStep,
      WhoStep
    );

    if (audienceOption === 'own') {
      baseComponents.push(IdentifyStep);
    }

    baseComponents.push(ReviewStep);

    baseSaveFunctions.push(
      (state) => saveObjectiveStep(state),
      (state) => saveDiscussionGuideStep(state),
      () => Promise.resolve(),
      (state) => saveWhoStep(state),
    );

    if (audienceOption === 'own') {
      baseSaveFunctions.push((state) => saveIdentifyStep(state));
    }

    baseSaveFunctions.push(
      (state) => saveReviewStep(state),
      () => Promise.resolve()
    );
        
    return {
      steps: baseSteps,
      components: baseComponents,
      saveFunctions: baseSaveFunctions,
      generateDiscussionGuideStepIndex: selectedBlock === 3 ? 3 : 1
    };
  };

  const getStepType = (step, selectedBlock, audienceOption) => {
    const { steps } = getStepConfig(selectedBlock, audienceOption);
    return steps[step]?.type || null;
  };
  
  // Check if the current step is of a given type
  const isStepOfType = (step, type, selectedBlock, audienceOption) => {
    return getStepType(step, selectedBlock, audienceOption) === type;
  };
  

  const {
    steps,
    components,
    saveFunctions,
    generateDiscussionGuideStepIndex
  } = useMemo(() => getStepConfig(selectedBlock, audienceOption), [selectedBlock, audienceOption]);



  const checkAndGenerateDiscussionGuide = () => {
    const objectiveChanged = researchObjective !== initialObjective;
    const contextChanged = conceptDescription !== initialContext;
    const creativeTypeChanged = selectedCreativeType !== initialCreativeType;
    const creativeTestMethodChanged = selectedCreativeTestMethod !== initialCreativeTestMethod;
    const creativeQuantityChanged = selectedCreativeQuantity !== initialCreativeQuantity;
    const attributeIdsChanged = attributeIds !== initialAttributeIds;

    

    if (objectiveChanged || creativeTypeChanged || creativeTestMethodChanged || creativeQuantityChanged || attributeIdsChanged) {
        setIsGeneratingDiscussionGuide(true);
        setProgress(0);
        setProgressStatus("Initializing...");

        return new Promise((resolve, reject) => {
            const pollProgress = async (jobId) => {
                try {
                    const status = await fetchGenerateDiscussionGuideStatus(jobId);

                    setProgress(status.progress );
                    setProgressStatus(status.current_step);

                    if (status.status === "completed") {
                        setDiscussionGuide(status.result.discussion_guide);
                        setIsGeneratingDiscussionGuide(false);
                        setInitialObjective(status.result.business_objective);
                        setInitialDiscussionGuide(status.result.discussion_guide);
                        setInitialAttributeIds(status.result.attribute_ids);
                        setInitialCreativeType(status.result.creative_type);
                        setInitialCreativeTestMethod(status.result.test_display_method);
                        setInitialCreativeQuantity(status.result.creatives_test_per_participant);
                        resolve();
                    } else if (status.status === "failed") {
                        console.error("Error generating questions:", status.error);
                        setProgressStatus("Error occurred");
                        setIsGeneratingDiscussionGuide(false);
                        reject(new Error(status.error));
                    } else {
                        // Continue polling every 30 seconds
                        setTimeout(() => pollProgress(jobId), 5000);
                    }
                } catch (error) {
                    console.error("Error fetching status:", error);
                    setProgressStatus("Error occurred");
                    setIsGeneratingDiscussionGuide(false);
                    reject(error);
                }
            };

            // Start the process
            setTimeout(async () => {
              try {
                const instruction = {
                  researchObjective,
                  contextDescription: conceptDescription,
                  selectedCreativeType,
                  selectedCreativeTestMethod,
                  selectedCreativeQuantity,
                  attributeIds,
                };
      
                if (!initialDiscussionGuide || initialDiscussionGuide.length === 0) {
                  const { job_id } = await generateCampaignDiscussionGuide({ campaignId, getAccessTokenSilently, getIdTokenClaims, instruction });
                  
                  pollProgress(job_id);
                } else {
                  const oldCampaignData = {
                    old_research_objective: initialObjective,
                    old_attribute_ids: initialAttributeIds,
                    old_discussion_guide: initialDiscussionGuide,
                  };
      
                  const { job_id } = await ApiUtils.updateCampaignDiscussionGuide({ campaignId, getAccessTokenSilently, getIdTokenClaims, oldCampaignData });
                  
                  pollProgress(job_id);
                }
              } catch (error) {
                console.error("Error generating or updating questions:", error);
                setProgressStatus("Error occurred");
                setIsGeneratingDiscussionGuide(false);
                reject(error);
              }
            }, 0);
        });
    } else {
        return Promise.resolve();
    }
};

  
  // Fetch the status of the job
  const fetchGenerateDiscussionGuideStatus = async (jobId) => {
    const accessToken = await getAccessTokenSilently();
    const idTokenClaims = await getIdTokenClaims();
    const idToken = idTokenClaims.__raw;
  
    const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/generate-questions-status/${jobId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-ID-Token': idToken,
        'Content-Type': 'application/json',
      },
    });
  
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to fetch status: ${errorText}`);
    }
  
    const data = await response.json();
    
    return data;
  };
  



  const CurrentComponent = components[step];

  const updateCampaign = async (campaignData) => {
      try {
        const updatedCampaign = await ApiUtils.updateCampaign({campaignId: campaignId, getAccessTokenSilently, getIdTokenClaims, campaignData: campaignData});
        return updatedCampaign;
      } catch (error) {
          console.error('Error updating campaign:', error);
          throw error;
      }
  };

  const handleSaveRecruitmentCriteria = () => {
    // Collect the selected recruitment criteria
    const recruitmentCriteria = {
      countries: selectedCountry.map(c => c.label),
      state: selectedState.map(s => s.label),
      age: { min: minAge, max: maxAge },
      education: selectedEducation.map(e => e.label),
      occupation: selectedOccupation.map(o => o.label),
      incomeRange: selectedIncomeRange.map(i => i.label),
      gender: selectedGender?.label,
      language: selectedLanguage?.map(l => l.label),
    };

    // Save the recruitment criteria and close the sheet
    setSavedRecruitmentCriteria(recruitmentCriteria);
    setShowRecruitmentSheet(false);
  };

  const handleSaveScreenerQuestions = () => {
    // Collect the selected screener questions
    const screenerQuestionList = [...screenerQuestions]; // Assuming you have screener questions logic elsewhere
    setSavedScreenerQuestions(screenerQuestionList);
    setShowScreenerSheet(false);
  };



  const handleEditRecruitmentCriteria = () => {
    setShowRecruitmentSheet(true);
  };

  // Function to edit screener questions (re-open the screener sheet)
  const handleEditScreenerQuestions = () => {
    setShowScreenerSheet(true);
  };

  

  const validateRecruitmentSection = () => {
    const errors = {
      participants: "",
      incentive: "",
      country: "",
      screenerQuestions: ""
    };
  
    if (numberOfParticipants <= 0) {
      errors.participants = "Number of participants must be greater than zero.";
    }
  
    if (incentiveAmount < 5) {
      errors.incentive = "Incentive amount must be at least $5.";
    }
  
    if (!selectedCountry || selectedCountry.length === 0) {
      errors.country = "Please select at least one country.";
    }
  
    if (!screenerQuestions || screenerQuestions.length < 2) {
      errors.screenerQuestions = "Please add at least two screener questions.";
    }
  
    setValidationErrors(errors);
  
    return Object.values(errors).every((error) => error === "");
  };



  const handleQuestionTypeChange = (questionId, newQuestionType) => {
    setScreenerQuestions(prevQuestions => 
      prevQuestions.map(q => {
        if (q.id === questionId) {
          const minOptions = newQuestionType === 'multiple' ? 2 : 2;
          let updatedOptions = q.options;

          // Ensure there are at least minOptions for the selected question type
          if (updatedOptions.length < minOptions) {
            const optionsToAdd = minOptions - updatedOptions.length;
            for (let i = 0; i < optionsToAdd; i++) {
              updatedOptions.push({ text: '', action: '' });
            }
          }

          return { ...q, questionType: newQuestionType, options: updatedOptions };
        }
        return q;
      })
    );
  };


  const handleStepChange = (newStep, navigatedBack) => {

    if (navigatedBack) {
      

      if ( step === 3) {
        
        if (selectedBlock === 1 || selectedBlock === 4) {
          setStep(newStep);
        } else {
          setStep(newStep);
        }
      }
    else {
        setStep(newStep);
      }
    }
    else {
      setStep(newStep);
    }
  };

  const handleSave = () => {
    saveCurrentStep();
    checkAndGenerateDiscussionGuide();
  };

  const hasNonEmptyCriteria = (criteria) => {
    if (!criteria) return false;
    const { countries, state, age, education, occupation, incomeRange, gender, language } = criteria;
    return (
      (countries && countries.length > 0) ||
      (state && state.length > 0) ||
      ((age && (age.min || age.max))) ||
      (education && education.length > 0) ||
      (occupation && occupation.length > 0) ||
      (incomeRange && incomeRange.length > 0) ||
      (gender && gender !== '') ||
      (language && language.length > 0)
    );
  };

  const handleOptionChange = (questionId, optionIndex, newText) => {
    setScreenerQuestions(prevQuestions =>
      prevQuestions.map(q =>
        q.id === questionId
          ? {
              ...q,
              options: q.options.map((option, idx) =>
                idx === optionIndex ? { ...option, text: newText } : option
              ),
            }
          : q
      )
    );
  };

  const handleAnswerTextChange = (questionId, newText) => {
    setScreenerQuestions(prevQuestions =>
      prevQuestions.map(q =>
        q.id === questionId
          ? { ...q, answerText: newText }
          : q
      )
    );
  };
  

    const handleOptionActionChange = (questionId, optionIndex, newAction) => {
      setScreenerQuestions(prevQuestions =>
        prevQuestions.map(q =>
          q.id === questionId
            ? {
                ...q,
                options: q.options.map((option, idx) =>
                  idx === optionIndex ? { ...option, action: newAction } : option
                ),
              }
            : q
        )
      );
    };

    const handleScreenerDragEnd = (result) => {
      if (!result.destination) return;
    
      const items = Array.from(screenerQuestions);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
    
      setScreenerQuestions(items);
    };
    
    const handleDeleteScreenerQuestion = (questionId) => {
      setScreenerQuestions((prevQuestions) =>
        prevQuestions.filter((q) => q.id !== questionId)
      );
    };

    const validateParticipants = (value) => {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        participants: value > 0 ? "" : "Number of participants must be greater than zero."
      }));
    };

    const validateExternalTitle = (value) => {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        externalProjectTitle: value.trim() !== '' ? "" : "External project title cannot be empty."
      }));
    };

    const validateExternalDescription = (value) => {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        externalProjectDescription: value.trim() !== '' ? "" : "External project Description cannot be empty."
      }));
    };
    
    
    const validateIncentive = (value) => {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        incentive: value >= 5 ? "" : "Incentive amount must be at least $5."
      }));
    };
    
    const validateCountry = (selectedCountries) => {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        country: selectedCountries && selectedCountries.length > 0 ? "" : "Please select at least one country."
      }));
    };
    
    const validateScreenerQuestions = (questions) => {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        screenerQuestions: questions.length >= 2 ? "" : "Please add at least two screener questions."
      }));
    };


  const handleParticipantsChange = (e) => {
    const value = e.target.value;
    setNumberOfParticipants(value);
    validateParticipants(value); 
  };


  const handleExternalTitleChange = (e) => {
    const value = e.target.value;
    setExternalProjectTitle(value);
    validateExternalTitle(value);  
  };

  const handleExternalDescriptionChange = (e) => {
    const value = e.target.value;
    setExternalProjectDescription(value);
    validateExternalDescription(value);  
  };


  const handleIncentiveChange = (e) => {
    const value = e.target.value;
    setIncentiveAmount(value);
    validateIncentive(value);  
  };


  const handleScreenerQuestionUpdate = (updatedQuestions) => {
    setScreenerQuestions(updatedQuestions);
    validateScreenerQuestions(updatedQuestions); 
  };

  const validateResearchObjective = (objective) => {
  const errors = {};

    if (!objective || !objective.trim()) {
      errors.researchObjective = "Please enter a business objective to continue.";
    } else if (objective.trim().split(/\s+/).length < 5) { // Check for at least 5 words
      errors.researchObjective = "Please provide more details about your research objective. This will help us craft an effective discussion guide tailored to your research needs.";
    }


    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      ...errors,
    }));

    return Object.keys(errors).length === 0;
  };

  const validateCreativeType = () => {
    const errors = {};
  
    if (!selectedCreativeType) {
      errors.creativeType = "Please select a concept type.";
    }
  
    if (!selectedCreativeQuantity) {
      errors.creativeQuantity = "Please select how many creatives you want to test per participant";
    }

    if(selectedCreativeQuantity!="1" && !selectedCreativeTestMethod) {
      errors.creativeTestMethod = "Please select how do you want to test the concept."
    }
  
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      ...errors,
    }));
  
    return Object.keys(errors).length === 0;
  };
    

  const validateDiscussionGuide = () => {
    if (isStepOfType(step, 'discussion_guide', selectedBlock, audienceOption)) {
      let cleanedDiscussionGuide = discussionGuide.filter((topic) => {
        const isTitleEmpty = !topic.topic_title?.trim();
        const isContextEmpty = !topic.context?.trim();
        const isQuestionsEmpty = !topic.questions || topic.questions.length === 0;
  
        if (isTitleEmpty && isContextEmpty && isQuestionsEmpty) {
          return false;
        }
        return true;
      });
  
      if (cleanedDiscussionGuide.length !== discussionGuide.length) {
        setDiscussionGuide(cleanedDiscussionGuide);
        
        if (cleanedDiscussionGuide.length > 0) {
          setSelectedTopicId(cleanedDiscussionGuide[0].topic_id);
        } else {
          setSelectedTopicId(null); 
        }
      }
  
      let discussionGuideErrors = {};

      cleanedDiscussionGuide.forEach((topic) => {
        const errorsForTopic = {};
        if (!topic.topic_title?.trim()) {
          errorsForTopic.title = "Please add a title for this topic";
        }
        if (!topic.context?.trim()) {
          errorsForTopic.context = "Please add context for this topic";
        }
        if (!topic.questions || topic.questions.length === 0) {
          errorsForTopic.questions = "Please add questions or generate questions for this topic using the Generate Questions button";
        }
    
        if (Object.keys(errorsForTopic).length > 0) {
          discussionGuideErrors[topic.topic_id] = errorsForTopic;
        }
      });
    
      if (Object.keys(discussionGuideErrors).length > 0) {
        // Pick the first incomplete topic ID so we can select it
        const [firstIncompleteTopicId] = Object.keys(discussionGuideErrors);
    
        // Store these errors in validationErrors
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          discussionGuideErrors,
        }));
    
        // Make sure the UI selects the incomplete topic
        setSelectedTopicId(firstIncompleteTopicId);
        return null;
      } else {
        // Clear any previous discussion guide errors
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          discussionGuideErrors: {},
        }));
        return cleanedDiscussionGuide;
      }
    }
  }



  useEffect(() => {
    if (showScreenerSheet && screenerQuestions.length === 0) {
      addNewQuestion('multiple');
    }
  }, [showScreenerSheet]);


  useEffect(() => {
    
  const fetchCampaignData = async () => {
    if (campaignId) {
      setFullLoading(true); 
       
      try {
        const fetchedCampaign = await ApiUtils.fetchCampaignData({campaignId:campaignId, getAccessTokenSilently, getIdTokenClaims});
        setCampaign(fetchedCampaign);
        
      } catch (error) {
        console.error('Error fetching campaign data:', error);
      } finally {
        setFullLoading(false); 
      }
    }
    else {
      setStep(0);
      setCampaignName('');
      setSelectedBlock(null);
      setIdentificationMethod('no_identify'); 
      setSelectedFieldsForLink([]); 
      setSelectedFieldsForAsk([]); 
  }
  };

    fetchCampaignData();
  }, [id]);


  useEffect(() => {
    mixpanel.track('CampaignBuilderViewed');
  }, []);




  useEffect(() => {
    if (audienceOption === 'recruit') {
      validateCountry(selectedCountry);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (audienceOption === 'recruit') {
      validateScreenerQuestions(screenerQuestions);
    }
  }, [screenerQuestions]);


  useEffect(() => {
  if (campaign) {
    setStep(initialStep !== null ? initialStep : (campaign.current_step || 0));
    setCampaignName(campaign.name || null);
    setAudienceOption(campaign.audience_option);
    setTargetAudience(campaign.target_audience);
    setResearchObjective(campaign.business_objective );
    setAttributeIds(campaign.attribute_ids);
    setInitialAttributeIds(campaign.attribute_ids);
    setDiscussionGuide(campaign.discussion_guide);
    setInitialDiscussionGuide(campaign.discussion_guide);
    setTestLink(campaign.test_link);
    setInitialObjective(campaign.business_objective);
    setLiveLink(campaign.live_link);
    setCreatives(campaign.creatives || []);
    setSelectedCreativeType(campaign.creative_type || null);
    setInitialCreativeType(campaign.creative_type || null);
    setSelectedCreativeQuantity(campaign.creatives_test_per_participant || null);
    setInitialCreativeQuantity(campaign.creatives_test_per_participant || null);
    setSelectedCreativeTestMethod(campaign.test_display_method || null);
    setInitialCreativeTestMethod(campaign.test_display_method || null);
    
    const typeToBlockMap = {
      'consumer_research': 1,
      'ad_testing': 2,
      'concept_testing': 3,
      'consumer_feedback': 4
    };
    setSelectedBlock(typeToBlockMap[campaign.type] || null);
    

    applyRecruitmentSettings(campaign);

    if (campaign.identification_method) {
      setIdentificationMethod(campaign.identification_method);

      if (campaign.identification_method === 'link') {
        setSelectedFieldsForLink(campaign.identification_fields?.fields || []);
      } else if (campaign.identification_method === 'ask') {
        setSelectedFieldsForAsk(campaign.identification_fields?.fields || []);
      }
    }

    } else {
      setStep(initialStep !== null ? initialStep : 0);
      setCampaignName(null);
      setSelectedBlock(null);
      setIdentificationMethod('no_identify'); 
      setSelectedFieldsForLink([]); 
      setSelectedFieldsForAsk([]); 
  }
  }, [campaign]);


  
  
    if (fullLoading) {
      return (
        <div className="loading-indicator-container">
          <div className="loading-indicator">
          </div>
        </div>
      );
    }

  return (
    
    <div className="campaign-builder">
          {showPostLiveStep ? (
          <PostLiveStep 
          handleBackClick={handleBackClick}
          campaign={campaign} />
        ) : (

          <><div className="builder-header">
            <div className="back-button-campaign-builder" onClick={handleBackClick}>
             <img src={cross} className='cross-icon'/>
            </div>

            <input
              type="text"
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
              placeholder="Add project name"
              className="campaign-name-input" />

            <div className="button-container">
                    {step > 0 && (
                      <button className="button-container-back-button" onClick={() => handleStepChange((step - 1), true)}>
                        {/* <FaArrowLeft />  */}
                        Previous
                      </button>

                    )}
                    <button className="save-button" onClick={handleSave}>
                      {/* <FaSave /> */}
                       Save
                    </button>
                    <button
                      className="continue-button"
                      onClick={continueStep}
                      disabled={loading || isGeneratingDiscussionGuide}
                    >
                      {loading ? (
                        <ThreeDots color="#fff" height={10} width={24} />
                      ) : (
                        step === steps.length - 1 ? "Submit" : "Next"
                      )}
                    </button>

                  </div>

          </div>
          
          <div className="campaign-builder-container">


              <RecruitmentCriteriaBottomSheetStep
                showRecruitmentSheet={showRecruitmentSheet}
                setShowRecruitmentSheet={setShowRecruitmentSheet}
                selectedCriteria={selectedCriteria}
                setSelectedCriteria={setSelectedCriteria}
                availableCriteria={availableCriteria}
                setAvailableCriteria={setAvailableCriteria}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                selectedState={selectedState}
                setSelectedState={setSelectedState}
                selectedEducation={selectedEducation}
                setSelectedEducation={setSelectedEducation}
                selectedOccupation={selectedOccupation}
                setSelectedOccupation={setSelectedOccupation}
                selectedIncomeRange={selectedIncomeRange}
                setSelectedIncomeRange={setSelectedIncomeRange}
                selectedGender={selectedGender}
                setSelectedGender={setSelectedGender}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                minAge={minAge}
                setMinAge={setMinAge}
                maxAge={maxAge}
                setMaxAge={setMaxAge}
                handleCriteriaSelect={handleCriteriaSelect}
                handleCriteriaDeselect={handleCriteriaDeselect}
                handleSaveRecruitmentCriteria={handleSaveRecruitmentCriteria}
                countryOptions={countryOptions}
                stateOptions={stateOptions}
                educationOptions={educationOptions}
                occupationOptions={occupationOptions}
                incomeRangeOptions={incomeRangeOptions}
                genderOptions={genderOptions}
                languageOptions={languageOptions} />

              <ScreenerQuestionsBottomSheetStep
                showScreenerSheet={showScreenerSheet}
                setShowScreenerSheet={setShowScreenerSheet}
                screenerQuestions={screenerQuestions}
                setScreenerQuestions={setScreenerQuestions}
                handleSaveScreenerQuestions={handleSaveScreenerQuestions}
                addNewQuestion={addNewQuestion}
                handleQuestionTextChange={handleQuestionTextChange}
                handleQuestionTypeChange={handleQuestionTypeChange}
                handleDeleteScreenerQuestion={handleDeleteScreenerQuestion}
                handleAddOption={handleAddOption}
                handleRemoveOption={handleRemoveOption}
                handleOptionChange={handleOptionChange}
                handleOptionActionChange={handleOptionActionChange}
                handleAnswerTextChange={handleAnswerTextChange}
                handleToggleRequired={handleToggleRequired}
                questionTypeOptions={questionTypeOptions}
                multipleChoiceActionOptions={multipleChoiceActionOptions}
                singleChoiceActionOptions={singleChoiceActionOptions}
                handleScreenerDragEnd={handleScreenerDragEnd}
                handleScreenerQuestionUpdate={handleScreenerQuestionUpdate} />

              <div className="builder-container" onClick={(e) => e.stopPropagation()}>
              <ProgressContainer
                steps={steps}
                currentStep={step}
                onStepChange={handleStepChange}
              />

                <div className="builder-content">
                {CurrentComponent && (
                      <CurrentComponent
                        // General Props (used by multiple components)
                        selectedBlock={selectedBlock}
                        onSelectBlock={setSelectedBlock}
                        researchObjective={researchObjective}
                        setResearchObjective={setResearchObjective}

                        audienceOption={audienceOption}
                        setAudienceOption={setAudienceOption}
                        numberOfParticipants={numberOfParticipants}
                        setNumberOfParticipants={setNumberOfParticipants}
                        incentiveAmount={incentiveAmount}
                        setIncentiveAmount={setIncentiveAmount}
                        validationErrors={validationErrors}
                        setValidationErrors={setValidationErrors}
                        validationError={validationErrors.researchObjective}
                        handleBackClick={handleBackClick}
                        handleSave={handleSave}
                        handleContinue={continueStep}

                        // Recruitment Criteria Props
                        showRecruitmentSheet={showRecruitmentSheet}
                        setShowRecruitmentSheet={setShowRecruitmentSheet}
                        selectedCriteria={selectedCriteria}
                        setSelectedCriteria={setSelectedCriteria}
                        availableCriteria={availableCriteria}
                        setAvailableCriteria={setAvailableCriteria}
                        selectedCountry={selectedCountry}
                        setSelectedCountry={setSelectedCountry}
                        selectedState={selectedState}
                        setSelectedState={setSelectedState}
                        selectedEducation={selectedEducation}
                        setSelectedEducation={setSelectedEducation}
                        selectedOccupation={selectedOccupation}
                        setSelectedOccupation={setSelectedOccupation}
                        selectedIncomeRange={selectedIncomeRange}
                        setSelectedIncomeRange={setSelectedIncomeRange}
                        selectedGender={selectedGender}
                        setSelectedGender={setSelectedGender}
                        selectedLanguage={selectedLanguage}
                        setSelectedLanguage={setSelectedLanguage}
                        minAge={minAge}
                        setMinAge={setMinAge}
                        maxAge={maxAge}
                        setMaxAge={setMaxAge}
                        handleCriteriaSelect={handleCriteriaSelect}
                        handleCriteriaDeselect={handleCriteriaDeselect}
                        savedRecruitmentCriteria={savedRecruitmentCriteria}
                        handleSaveRecruitmentCriteria={handleSaveRecruitmentCriteria}
                        hasNonEmptyCriteria={hasNonEmptyCriteria}
                        handleParticipantsChange={handleParticipantsChange}
                        handleExternalTitleChange={handleExternalTitleChange}
                        handleExternalDescriptionChange={handleExternalDescriptionChange}
                        handleIncentiveChange={handleIncentiveChange}
                        editIndex={editIndex}
                        setEditIndex={setEditIndex}
                        newQuestion={newQuestion}
                        setNewQuestion={setNewQuestion}

                        // Screener Questions Props
                        showScreenerSheet={showScreenerSheet}
                        setShowScreenerSheet={setShowScreenerSheet}
                        screenerQuestions={screenerQuestions}
                        setScreenerQuestions={setScreenerQuestions}
                        savedScreenerQuestions={savedScreenerQuestions}
                        addNewQuestion={addNewQuestion}
                        handleSaveScreenerQuestions={handleSaveScreenerQuestions}
                        handleQuestionTextChange={handleQuestionTextChange}
                        handleQuestionTypeChange={handleQuestionTypeChange}
                        handleDeleteScreenerQuestion={handleDeleteScreenerQuestion}
                        handleAddOption={handleAddOption}
                        handleRemoveOption={handleRemoveOption}
                        handleOptionChange={handleOptionChange}
                        handleOptionActionChange={handleOptionActionChange}
                        handleAnswerTextChange={handleAnswerTextChange}
                        handleToggleRequired={handleToggleRequired}
                        handleEditRecruitmentCriteria={handleEditRecruitmentCriteria}
                        handleEditScreenerQuestions={handleEditScreenerQuestions}
                        handleAddCustomField={handleAddCustomField}
                        handleRemoveField={handleRemoveField}
                        handleAddField={handleAddField}
                        externalProjectTitle={externalProjectTitle}
                        externalProjectDescription={externalProjectDescription}
                        creatives={creatives}
                        setCreatives={setCreatives}
                        // Identification Props (used in IdentifyStep)
                        identificationMethod={identificationMethod}
                        setIdentificationMethod={setIdentificationMethod}
                        selectedFieldsForLink={selectedFieldsForLink}
                        setSelectedFieldsForLink={setSelectedFieldsForLink}
                        selectedFieldsForAsk={selectedFieldsForAsk}
                        setSelectedFieldsForAsk={setSelectedFieldsForAsk}
                        customField={customField}
                        setCustomField={setCustomField}
                        isCustomField={isCustomField}
                        setIsCustomField={setIsCustomField}
                        getIdTokenClaims={getIdTokenClaims}
                        getAccessTokenSilently={getAccessTokenSilently}
                        handleCreativeUpload={handleCreativeUpload} 
                        setAttributes={setAttributes}
                        attributes={attributes}
                        attributeIds={attributeIds}
                        setAttributeIds={setAttributeIds}
                        discussionGuide={discussionGuide}
                        setDiscussionGuide={setDiscussionGuide}
                        progress={progress}
                        progressStatus={progressStatus}
                        setSelectedTopicId={setSelectedTopicId}
                        selectedTopicId={selectedTopicId}
                        discussionGuideErrors={validationErrors.discussionGuideErrors}
                        removeDiscussionGuideError={removeDiscussionGuideError}
                        // Other Props
                        campaignId={campaignId}
                        campaignName={campaignName}
                        setCampaignName={setCampaignName}
                        initialObjective={initialObjective}
                        initialContext={initialContext}
                        isGeneratingDiscussionGuide={isGeneratingDiscussionGuide}
                        handleStepChange={handleStepChange}
                        selectedCreativeType={selectedCreativeType}
                        setSelectedCreativeType={setSelectedCreativeType}
                        selectedCreativeQuantity={selectedCreativeQuantity}
                        setSelectedCreativeQuantity={setSelectedCreativeQuantity}
                        selectedCreativeTestMethod={selectedCreativeTestMethod}
                        setSelectedCreativeTestMethod={setSelectedCreativeTestMethod}
                        testLink={testLink}
                        conceptDescription={conceptDescription}
                        setConceptDescription={setConceptDescription}
                        suggestedFieldsForLink={suggestedFieldsForLink}
                        setSuggestedFieldsForLink={setSuggestedFieldsForLink}
                        suggestedFieldsForAsk={suggestedFieldsForAsk}
                        setSuggestedFieldsForAsk={setSuggestedFieldsForAsk}
                        clearValidationError={clearValidationError}
                        
                    
                      />
                    )}

                </div>

              </div>
            </div></>
        )}
        </div>
      
  );
};

export default CampaignBuilder;