import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { MdHome, MdBarChart, MdSearch, MdChevronLeft, MdChevronRight, 
  MdAccountCircle, MdContentCopy, MdMoreVert, MdMenuBook, MdArrowDropUp, MdArrowDropDown, 
  MdNewLabel, MdLabel, MdArrowRight, MdArrowLeft,MdExitToApp , MdArrowUpward, MdDelete} from 'react-icons/md';
import logo from '../assets/echovane_logo.png';
import mixpanel from 'mixpanel-browser';
import ConfirmDialog from './ConfirmDialog';
import consumer_research from '../assets/research.svg';
import customer_feedback from '../assets/feedback.svg';
import concept_testing from '../assets/concept.svg';
import how_to from '../assets/how_to.png';
import create_new from '../assets/create_new.svg';
import plus from '../assets/plus.svg';
import edit from '../assets/edit.svg';
import upload from '../assets/tta.svg';
import ai_moderated from '../assets/ai_moderated.svg';
import consumer_research_small from '../assets/research_small.svg';
import concept_small from '../assets/concept_small.svg';
import feedback_small from '../assets/feedback_small.svg';
import transcribe_small from '../assets/tta_small.svg';
import { parse, formatDistanceToNow } from "date-fns";
import {  toZonedTime, formatInTimeZone, /* etc. */ tzParse } from "date-fns-tz";
import { FiFileText } from 'react-icons/fi'; // For a modern transcript icon


import transcribe_analyze from '../assets/transcribe_analyze.svg';
import live_copy from '../assets/live_copy.svg';
import test_copy from '../assets/test_copy.svg';
import report_icon from '../assets/report_icon.svg';
import transcripts_icon from '../assets/transcripts_icon.svg';
import recruit_icon from '../assets/recruit_icon.svg';
import business_objective from '../assets/business_objective.png';
import { createCampaign } from './ApiUtils';


mixpanel.init('1702428ef9acdb5aa84df81256d5fe13', { debug: false, track_pageview: false, persistence: 'localStorage' });




const HomePage = (props) => {

  const { interviewMinutesUsed, interviewMinutesLimit, planType } = props; 
  
  useEffect(() => {
    mixpanel.track('DashboardHomePageViewed');
  }, []);

  const [drawerOpen, setDrawerOpen] = useState(true); // For expanding and collapsing drawer
  const [userMenuOpen, setUserMenuOpen] = useState(false); // For user dropdown
  
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  
  const toggleUserMenu = () => {
    setUserMenuOpen(!userMenuOpen);
  };
  
  
  

  const [campaigns, setCampaigns] = useState([]);
  const [backendAuthComplete, setBackendAuthComplete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [paginationLoading, setPaginationLoading] = useState(false);

  const [accessToken, setAccessToken] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total_count, setTotalCount] = useState(0);
  const pageSize = 6; 
  const [copiedMessage, setCopiedMessage] = useState('');
  const [menuOpen, setMenuOpen] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const menuRef = useRef(null);
  const [deleteError, setDeleteError] = useState(null);
  const campaignsFetched = useRef(false);
  const [showText, setShowText] = useState(true);
  const drawerRef = useRef(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [dropdownDirection, setDropdownDirection] = useState('below');
  const [greeting, setGreeting] = useState('');
  const pollingIntervalRef = useRef(null);


  const getRelativeTime = (timestamp) => {
    console.log("timestamp ", timestamp);
    if (!timestamp) return "Unknown";
    try {

  
      // formatDistanceToNow will compare dateInUtc to "now" in local time
      let distance = formatDistanceToNow(timestamp, { addSuffix: true });
  
      // Remove "about" and replace "minutes" and "hours" with their short versions
      distance = distance
        .replace(/about\s*/g, "") // Remove "about"
        .replace(/\bminutes\b/g, "mins") // Replace "minutes" with "mins"
        .replace(/\bhours\b/g, "hrs"); // Replace "hours" with "hrs"
  
      
      console.log("distance ", distance);
      return distance;
      
    } catch (error) {
      console.error("Error parsing timestamp:", error);
      return "Invalid date";
    }
  };
  

  
  
  

  const handleResearchTypeClick = async (blockId) => {
    try {
      setLoading(true);

      const selectedBlock = blockId;
      const projectType = "ai_moderated";
  
      const campaign = await createCampaign({
        selectedBlock,
        campaignName:null,
        getAccessTokenSilently,
        getIdTokenClaims,
        projectType
      });
  
      if (campaign && campaign.id) {
        navigate(`/research/${campaign.id}?step=1`);
      }
    } catch (error) {
      console.error('Error creating/updating campaign:', error);
    }
    finally {
      setLoading(false);
    }
  };

  const { loginWithRedirect, logout, user, isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0();


  const handlePlansClick = () => {
    navigate('/plans');
  };

  const pollAnalyzingCampaigns = async () => {
    const hasAnalyzingCampaigns = campaigns.some(campaign => campaign.status === 'Analyzing');
    if (hasAnalyzingCampaigns) {
      try {
        const accessToken = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        
        const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns?page=${currentPage}&page_size=${pageSize}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'X-ID-Token': idToken.__raw,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setCampaigns(data.campaigns);
        } else {
          console.error('Failed to poll campaigns');
        }
      } catch (error) {
        console.error('Error polling campaigns:', error);
      }
    }
  };

  useEffect(() => {

    const startPolling = () => {
      if (!pollingIntervalRef.current) {
        pollingIntervalRef.current = setInterval(pollAnalyzingCampaigns, 60000); // Poll every 2 minutes
      }
    };
  
    const stopPolling = () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
    };
  
    const hasAnalyzingCampaigns = campaigns.some(campaign => campaign.status === 'Analyzing');
    if (hasAnalyzingCampaigns) {
      startPolling();
    } else {
      stopPolling();
    }
  
    return () => {
      stopPolling(); // Clean up polling on unmount
    };
  }, [campaigns]);
  
  

  useEffect(() => {
    const currentHour = new Date().getHours();
  
    if (currentHour < 12) {
      setGreeting('Good Morning');
    } else if (currentHour < 18) {
      setGreeting('Good Afternoon');
    } else {
      setGreeting('Good Evening');
    }
  }, []);
  


  useEffect(() => {
    const handleClickOutside = (event) => {
      
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) && 
        !event.target.closest('.menu-button') 
      ) {
        setMenuOpen(null);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  useEffect(() => {
    const checkAndRedirect = async () => {
      if (!isLoading && !isAuthenticated) {
        try {
          await loginWithRedirect({
            appState: { returnTo: window.location.pathname }
          });
        } catch (error) {
          console.error('Login error:', error);
          setError('Failed to log in. Please try again.');
        }
      }
    };

    checkAndRedirect();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    const fetchTokens = async () => {
      if (isAuthenticated && user) {
        try {
          const accessToken = await getAccessTokenSilently();
          const idTokenClaims = await getIdTokenClaims();
        
          setAccessToken(accessToken);
          setIdToken(idTokenClaims.__raw);
        } catch (error) {
          console.error('Error fetching tokens:', error);
          setError('Failed to fetch authentication tokens. Please try logging in again.');
        }
      }
    };

    fetchTokens();
  }, [isAuthenticated, user]);

  const handleCampaignReportClick = (campaign, section = '') => {
    if (accessToken && idToken) {
      navigate(`/report/${campaign.id}`, {
        state: {
          accessToken,
          idToken,
          testLink: campaign.test_link,
          liveLink: campaign.live_link,
          section, // Pass section parameter
        },
      });
    } else {
      console.error('Tokens are not available');
    }
  };
  
  
  const handleViewParticipants = (campaign) => {
    navigate('/participants', { state: { campaignId: campaign.id } });
  };


  const showErrorMessage = (message) => {
    setError(message);
    setTimeout(() => {
      setError(null);
    }, 3000); // Hide error message after 3 seconds
  };
  

  const toggleMenu = (campaignId) => {
    setMenuOpen(menuOpen === campaignId ? null : campaignId);
  };

  const handleDeleteClick = (campaign) => {
    setCampaignToDelete(campaign);
    setShowDeleteConfirm(true);
    setMenuOpen(null);
  };

  const handleDeleteConfirm = async () => {
    if (campaignToDelete) {
      try {
        const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns/${campaignToDelete.id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'X-ID-Token': idToken,
          },
        });
  
        if (response.ok) {
          setCampaigns(campaigns.filter(c => c.id !== campaignToDelete.id));
          mixpanel.track('CampaignDeleted', { campaignId: campaignToDelete.id });
          setMenuOpen(null); 
          setDeleteError(null); 
          setShowDeleteConfirm(false);
        } else {
          const errorData = await response.json();
          showErrorMessage(errorData.message || 'Failed to delete campaign');
        }
      } catch (error) {
        console.error('Error deleting campaign:', error);
        showErrorMessage('An error occurred while deleting the campaign');
      }
    }
    setCampaignToDelete(null);
  };


  useEffect(() => {
    if (campaignsFetched.current) {
      return;
    }

    const fetchUserDataandCampaigns = async () => {
      if (isAuthenticated && user) {
        try {
          const accessToken = await getAccessTokenSilently();
          const idToken = await getIdTokenClaims();
    
          const response = await fetch('https://dashboard-api.echovane.com/api/auth/user', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'X-ID-Token': idToken.__raw,
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
              email: user.email,
            })
          });
          
          
          const responseData = await response.json();
  
          

          if (response.status === 200) {
            setBackendAuthComplete(true);
            fetchCampaigns(currentPage);
            campaignsFetched.current = true;
          } else {
            console.error('Backend authentication failed:', responseData);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };
  
    fetchUserDataandCampaigns();
  }, [isAuthenticated, user]);

  
  const handleCopyLink = (link, type) => {
    navigator.clipboard.writeText(link);
    setCopiedMessage(`${type} link copied`);
    
    setTimeout(() => {
      setCopiedMessage('');
    }, 3000); // Hide message after 3 seconds
  };

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };


  const fetchCampaigns = async (page = 1) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      
      const response = await fetch(`https://dashboard-api.echovane.com/api/campaigns?page=${page}&page_size=${pageSize}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'X-ID-Token': idToken.__raw
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);

        setCampaigns(data.campaigns);
        setTotalCount(data.total_count);
        setTotalPages(Math.ceil(data.total_count / pageSize));
        setCurrentPage(page);
      } else {
        console.error('Failed to fetch campaigns');
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setPaginationLoading(false);
      setLoading(false);
    }
  };
  
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setPaginationLoading(true); 
      fetchCampaigns(currentPage - 1);
    }
  };
  
  const handleNextPage = () => {
    if (currentPage < totalPages) {
        setPaginationLoading(true); 
      fetchCampaigns(currentPage + 1);
    }
  };

  const handleUpgradeClick = () => {
    navigate(`/subscriptions`);
  };
  

  const handleCampaignClick = (campaign) => {
    if (campaign.project_type === 'transcribe_analyse') {
      navigate(`/analyze/${campaign.id}`);
    }
    else if (campaign.status === 'Live'){
      navigate(`/research/${campaign.id}/live`);
    } 
    else {
      navigate(`/research/${campaign.id}`);
    }
  };
  
  
  useEffect(() => {
    const drawer = drawerRef.current;

    if (drawer) {
    if (drawerOpen) {
      const handleTransitionEnd = () => {
        setShowText(true);
      };
      
      drawer.addEventListener('transitionend', handleTransitionEnd);
  
      return () => {
  
        drawer.removeEventListener('transitionend', handleTransitionEnd);
      };
    } else {
      // Reset showText when the drawer is collapsing
      setShowText(false);
    }

  }
  }, [drawerOpen]);

  if (loading || isLoading ) {
    return <div className="loading-indicator-container">
              <div className="loading-indicator"></div>
            </div>;
  }

  if (!isAuthenticated) {
    return null;
  }

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  const handleCreateNewResearch = () => {
    navigate('/research');
  };


  const handleCreateNewAnalyze = async () => {
    try {
      setLoading(true);
  
      const selectedBlock = null;
      const projectType = "transcribe_analyse";
  
      const campaign = await createCampaign({
        selectedBlock,
        campaignName:null,
        getAccessTokenSilently,
        getIdTokenClaims,
        projectType,
      });
  
      if (campaign && campaign.id) {
        navigate(`/analyze/${campaign.id}`, {
          state: { campaign },
        });
      }
    } catch (error) {
      console.error('Error creating analysis campaign:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatProjectType = (projectType) => {
    if (!projectType) return '';
    return projectType
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  
  
  

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  

  return (
    <>

      {loading ? null : (
        <div className="app-container">
          

          <main className="home-main-content">

            <div className='greeting-row'>

            <div className='greeting'>
              {greeting}

            </div>
           
            </div> 


            {interviewMinutesUsed >= interviewMinutesLimit && planType === 'Free' && (

            <div className='notification-banner-section'>
            <div className='notification-banner-text-content'>
            <div className='notification-banner-title'>

            Awesome work! You’ve used all your interview minutes for this month on the free tier 🎉
            </div>
            <div className='notification-banner-description'>

            Upgrade to keep gaining insights and make the most out of your interviews! 
            </div>
            </div>

            <div className='notification-banner-actions'>
              <button className='positive-action' onClick={handleUpgradeClick}> Upgrade</button>
              
            </div>
            </div>
            
          )}
            
            <div className='create-project-container'>
            <div className='create-research-section'>
              <div className='create-project-title-row'>
              <img src={plus} className='create-project-title-icon'/>
              <div className='create-project-title'>Create Project</div>
              </div>
            
            {/* <div className='create-project-description'> Use AI to conduct in-depth user interviews at scale</div> */}
            <div className='create-project-content'>
              {/* <div className='create-project-item-new' onClick={handleCreateNewResearch}>

              <div className='create-project-item-content'>

                < img src={create_new} className='create-project-item-svg-new'></img>
                <p className='create-project-item-text'>From scratch</p>
                <div className='hover-description'>
                    Use this option to conduct consumer research and gather actionable insights.
                </div>

              </div>
              </div> */}
              <div className='create-project-row'>
              <div className='create-project-item' onClick={() => handleResearchTypeClick(1)}>
              <div className='create-project-item-content'>

                < img src={consumer_research} className='create-project-item-svg'></img>
                <div className='create-project-item-text-content'>
                <p className='create-project-item-text'>Consumer research</p>
                {/* <p className='create-project-item-description'> Do Consumer research and more research and more research</p> */}
                </div>
                <div className='hover-description'>
                Choose for more open ended conversations. Good for exploratory research on topics vs specific questions. Understand behaviours, opinions and preferences of your target audience.
                </div>

              </div>

              </div>
              <div className='create-project-item' onClick={() => handleResearchTypeClick(3)}>
                <div className='create-project-item-content'>

                  < img src={concept_testing} className='create-project-item-svg'></img>
                  <div className='create-project-item-text-content'>
                  <p className='create-project-item-text'>Concept testing</p>
                  {/* <p className='create-project-item-description'> Do Consumer research and more research and more research</p> */}
                  </div>
                  <div className='hover-description'>
                  Choose to test new ideas, products, or campaigns. Ideal for understanding audience reactions, identifying strengths, and refining concepts before launch.
                  </div>

                </div>

              </div>
              <div className='create-project-item' onClick={() => handleResearchTypeClick(4)}>

              <div className='create-project-item-content'>

                < img src={customer_feedback} className='create-project-item-svg'></img>
                <div className='create-project-item-text-content'>
                <p className='create-project-item-text'>User feedback</p>
                {/* <p className='create-project-item-description'> Do Consumer research and more research and more research</p> */}
                </div>
                <div className='hover-description'>
                Choose for specific feedback on a more or less known set of questions. Get user feedback on your current products and services.
                </div>
              </div>
              </div>

              <div className='create-project-item' onClick={() => handleCreateNewAnalyze()}>

                <div className='create-project-item-content'>

                < img src={upload} className='create-project-item-svg'></img>
                <div className='create-project-item-text-content'>
                <p className='create-project-item-text'>Transcribe & Analyze</p>
                {/* <p className='create-project-item-description'> Do Consumer research and more research and more research</p> */}
                </div>
                <div className='hover-description'>
                  Transcribe and analyse pre-recorded user conversations.
                </div>
                </div>
                </div>
              </div>

              <div className='create-project-row'>


              </div>
              
            </div>
            </div>


            </div>
            
            {campaigns.length === 0 ? (
              <div className="empty-state">
              </div>
            ) : (
              <>
                <div className="campaign-list-container" >
                <div className='your-project-title-row'>
              <img src={edit} className='create-project-title-icon'/>
              <div className='create-project-title'>Your Projects</div>
              </div>
                  <div className={`campaign-list ${paginationLoading ? 'loading' : ''}`}>

                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Status</th>
                          <th>Last Updated</th>
                          <th></th>  
                          <th> </th>  
                        </tr>
                      </thead>


                      <tbody>
                      {paginationLoading ? (
                        [...Array(pageSize)].map((_, idx) => (
                            <tr key={idx}>
                            <td className="shimmer"></td>
                            <td className="shimmer"></td>
                            <td className="shimmer"></td>
                            {/* <td className="shimmer"></td> */}
                            <td className="shimmer"></td>
                            </tr>
                        ))
                        ) : ( <>
                        {campaigns.map((campaign) => (
                          <tr key={campaign.id} className="campaign-row">
                            <td>
                              <div className="research-column">
                              {campaign.type === 'consumer_research' && (
                                  <img
                                    src={consumer_research_small}
                                    alt="AI Moderated Icon"
                                    className="research-icon"
                                    title="AI Moderated Research"
                                  />
                                )}
                                  {campaign.type === 'consumer_feedback' && (
                                  <img
                                    src={feedback_small}
                                    alt="Transcribe Analyze Icon"
                                    className="research-icon"
                                    title="Transcribe & Analyze"
                                  />
                                )}
                                 {campaign.type === 'concept_testing' && (
                                  <img
                                    src={concept_small}
                                    alt="Transcribe Analyze Icon"
                                    className="research-icon"
                                    title="Transcribe & Analyze"
                                  />
                                )}
                                 {campaign.project_type === 'transcribe_analyse' && (
                                  <img
                                    src={transcribe_small}
                                    alt="Transcribe Analyze Icon"
                                    className="research-icon"
                                    title="Transcribe & Analyze"
                                  />
                                )}


                                <a
                                  href="#"
                                  className="campaign-name-link"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleCampaignClick(campaign);
                                  }}
                                >
                                  {campaign.name || 'Untitled Project'}
                                </a>
                              </div>
                            </td>

                            {/* <td>{formatProjectType(campaign.project_type)}</td> */}
                            <td>
                            <span
                              className={`status-chip 
                                ${campaign.status === 'Live' ? 'live' : ''} 
                                ${campaign.status === 'Draft' || campaign.status === null ? 'draft' : ''} 
                                ${campaign.status === 'Analyzing' ? 'analyzing' : ''} 
                                ${campaign.status === 'Completed' ? 'completed' : ''}`}
                            >
                              {campaign.status ? campaign.status : 'Draft'}
                            </span>
                          </td>


                          <td >{getRelativeTime(campaign.updated_at)}</td>

                            
                              <td>
                              
                            <div className="action-icons">
                            {campaign.project_type === 'ai_moderated' && campaign.live_link && (
                                <div className="action-icon"  title="Copy Live Link"  onClick={() =>{
                                        let link = `https://feedback.echovane.com/interviews/${campaign.live_link}`;

                                        if (campaign.identification_method === 'link' && campaign.identification_fields?.fields) {
                                          const queryParams = campaign.identification_fields.fields
                                            .map(field => `${field}=xxxx`)
                                            .join('&');
                                          link += `?${queryParams}`;
                                        }

                                        handleCopyLink(link, 'Live');
                                      }}>
                                  <img src={live_copy} className='action-icon-svg'/>

                                </div>
                                )}
                                {campaign.project_type === 'ai_moderated' && campaign.test_link &&(
                                <div className="action-icon" title="Copy Test Link" onClick={() => {
                                      let link = `https://feedback.echovane.com/interviews/${campaign.test_link}`;

                                      // Check if identification method is 'link' and append fields with 'xxxx'
                                      if (campaign.identification_method === 'link' && campaign.identification_fields?.fields) {
                                        const queryParams = campaign.identification_fields.fields
                                          .map(field => `${field}=xxxx`)
                                          .join('&');
                                        link += `?${queryParams}`;
                                      }

                                      handleCopyLink(link, 'Test');
                                    }}>
                                  <img src={test_copy} className='action-icon-svg'/>
                                </div>
                                )}
                                
                                {campaign.audience_option === 'recruit' && (
                                  <div className="action-icon" title="View Recruits"  onClick={() => handleViewParticipants(campaign)}>
                                    <img src={recruit_icon} className='action-icon-svg'/>
                                  </div>
                                )}

                                {campaign.project_type === 'transcribe_analyse' && (
                                <div className="action-icon" title="View Transcripts" onClick={() => handleCampaignReportClick(campaign, 'transcripts')}>
                                <img src={transcripts_icon} className='action-icon-svg'/>
                            
                                </div>
                                )}
                                
                                <div className="action-icon" title="View Report" onClick={() => handleCampaignReportClick(campaign)}>
                                <img src={report_icon} className='action-icon-svg'/>
                            
                                </div>
                              </div>
                              </td>
                              <td>
                              <div  className="action-menu">
                                <button onClick={() => toggleMenu(campaign.id)} className="menu-button">
                                  <MdMoreVert />
                                </button>
                                {menuOpen === campaign.id && (
                                  <div  ref={menuRef} className="menu-dropdown">
                                    {campaign.project_type === 'ai_moderated' && campaign.live_link && (
                                    <button onClick={() =>  {
                                        let link = `https://feedback.echovane.com/interviews/${campaign.live_link}`;

                                        if (campaign.identification_method === 'link' && campaign.identification_fields?.fields) {
                                          const queryParams = campaign.identification_fields.fields
                                            .map(field => `${field}=xxxx`)
                                            .join('&');
                                          link += `?${queryParams}`;
                                        }

                                        handleCopyLink(link, 'Live');
                                      }} className="action-menu-option">
                                         <MdContentCopy className="dropdown-icon" />
                                        Copy Live Link
                                        </button>
                                    )}
                                    {campaign.project_type === 'ai_moderated' && campaign.test_link && (
                                    <button onClick={() => {
                                      let link = `https://feedback.echovane.com/interviews/${campaign.test_link}`;

                                      // Check if identification method is 'link' and append fields with 'xxxx'
                                      if (campaign.identification_method === 'link' && campaign.identification_fields?.fields) {
                                        const queryParams = campaign.identification_fields.fields
                                          .map(field => `${field}=xxxx`)
                                          .join('&');
                                        link += `?${queryParams}`;
                                      }

                                      handleCopyLink(link, 'Test');
                                    }} className="action-menu-option">
                                       <MdContentCopy className="dropdown-icon" />
                                      Copy Test Link
                                      </button>
                                  )}
                                    
                                    {campaign.audience_option === 'recruit' && (
                                      <button onClick={() => handleViewParticipants(campaign)} className="action-menu-option">
                                         <MdAccountCircle className="dropdown-icon" />
                                         View Recruits</button>
                                    )}
                                     {campaign.project_type === 'transcribe_analyse' && (
                                    <button onClick={() => handleCampaignReportClick(campaign, 'transcripts')} className="action-menu-option">
                                      <FiFileText className="dropdown-icon" />
                                      View Transcripts</button>
                                     )}
                                    <button onClick={() => handleCampaignReportClick(campaign)} className="action-menu-option">
                                    <MdBarChart className="dropdown-icon" />
                                    View Report</button>
                                    <button onClick={() => handleDeleteClick(campaign)} className="action-menu-option">
                                    <MdDelete className="dropdown-icon" />
                                    Delete</button>
                                  </div>
                                )}


                              </div>
                              
                            </td>
                          </tr>
                        ))}
                        </>     )}
                      </tbody>
                      

                    </table>
                    

                  </div>
                  {total_count > pageSize && (
              <div className="pagination-controls">
                <MdArrowLeft                   onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className="pagination-button"> </MdArrowLeft>

                <span>{currentPage} of {totalPages}</span>
                <MdArrowRight onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className="pagination-button"></MdArrowRight>
              </div>
            )}
                </div>
              </>
            )}


{/* 
            <div className='guides-section'>
              <div className='create-project-title'> Guides</div>
              <div className='guides-content'>
                <div className='guides-item'>
                <div className='guides-item-content'>

                  < img src={how_to} className='guides-item-content-svg'></img>
                  <p className='guides-item-content-text'>Mastering Research with Echovane: Top Tips for Smarter Insights</p>

                </div>
                </div>
                <div className='guides-item'>
                <div className='guides-item-content'>

                < img src={business_objective} className='guides-item-content-svg'></img>
                <p className='guides-item-content-text'>How to Write Clear and Effective Research Objectives</p>

                </div>
                </div>
                
              </div>
            </div> */}

          </main>
          {copiedMessage && (
              <div className="copied-message">
                {copiedMessage}
              </div>
            )}

        </div>
        
      )}

        <ConfirmDialog
          isOpen={showDeleteConfirm}
          options={[
            { label: 'Close', onClick: () => {
              setShowDeleteConfirm(false);
              setDeleteError(null);
              setCampaignToDelete(null);
            }, className: 'confirm-dialog-cancel-button' },
            { label: 'Confirm', onClick: handleDeleteConfirm, className: 'confirm-button' },
          ]}

          title="Are you sure you want to delete this campaign?"
          description="This action cannot be undone. This will permanently delete the campaign and all associated interviews and insight reports, if any."
        />
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}
        
    </>
  );
  
}

export default HomePage;