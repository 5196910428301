
import { FaSave, FaArrowLeft, FaTrash, FaDownload } from 'react-icons/fa'; 
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import React from 'react';
import upload from '../assets/upload.svg'; 


const Dropzone = ({ 
  requestPresignedUrls,
  deleteFile, 
  fetchFileDownloadUrl, 
  campaignId, 
  clientName, 
  getAccessTokenSilently, 
  getIdTokenClaims ,
  creatives,
  setCreatives,
  handleCreativeUpload,
  selectedCreativeQuantity,
  setValidationErrors,
  clearValidationError
}) => {
  
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [deletingFiles, setDeletingFiles] = useState({});
  const [backendFiles, setBackendFiles] = useState(() => 
    creatives?.map((creative) => ({
      id: creative.id, 
      file: { name: creative.concept_name || creative.file_name }, 
      uploaded: true,
      file_url: creative.file_url,
    })) || []
  );
  const [loading, setLoading] = useState(false); 

  const maxSize = 1073741824; 

  const handleAbort = (id) => {
    setDroppedFiles((prevFiles) =>
      prevFiles.filter((file) => {
        if (file.id === id && file.abortController) {
          file.abortController.abort(); // Abort the upload
          return false; // Exclude this file from the list
        }
        return true; // Keep other files
      })
    );
  };
  

  const onDrop = async (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      id: `${Date.now()}-${file.name}`,
      uploaded: false,
      abortController: new AbortController(),
    }));
  
    setDroppedFiles((prev) => [...prev, ...newFiles]);
    clearValidationError('uploadCreatives');
  
    try {
      const presignedUrls = await requestPresignedUrls({
        clientName: clientName,
        fileNames: newFiles.map((f) => f.file.name), 
        fileSizes: newFiles.map((f) => f.file.size), 
        uniqueId: campaignId, 
        getAccessTokenSilently, 
        getIdTokenClaims  
        
      });
  
      const uploadPromises = presignedUrls.map(async (fileUploadData) => {
        const fileWrapper = newFiles.find((f) => f.file.name === fileUploadData.file_name);
  
        try {

            await axios.put(fileUploadData.url, fileWrapper.file, {
              headers: { 'Content-Type': 'application/octet-stream' },
              signal: fileWrapper.abortController.signal,
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / fileWrapper.file.size
                );
              
                // Safely update uploadProgress state
                setUploadProgress((prev) => ({
                  ...prev,
                  [fileWrapper.file.name]: percentCompleted,
                }));
                
                // Use the updated value if needed elsewhere
              
              },
              
            });

  
          fileWrapper.uploaded = true;
          fileWrapper.id = fileUploadData.record_id; 
          fileWrapper.file_url = fileUploadData.url; 

          
          setDroppedFiles((prev) =>
            prev.map((f) => (f.id === fileWrapper.id ? { ...f, uploaded: true } : f))
          );


          if (selectedCreativeQuantity === 'all' && backendFiles.length + droppedFiles.length > 1) {

            handleCreativeUpload();
          }

          
        } catch (error) {
          console.error('Upload failed:', error);
        }
      });
  
      await Promise.all(uploadPromises);
    } catch (error) {
      console.error('Error during file upload:', error);
    }
  };
  

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': [], 'video/*': [] },
    maxSize,
  });
  

  const handleDelete = async (fileId) => {
    setDeletingFiles((prev) => ({ ...prev, [fileId]: true }));
  
    try {
      await deleteFile({campaignId: campaignId,fileId: fileId, getAccessTokenSilently, getIdTokenClaims });
      setBackendFiles((prev) => prev.filter((file) => file.id !== fileId));
      setDroppedFiles((prev) => prev.filter((file) => file.id !== fileId));
    } catch (error) {
      console.error('Error deleting file:', error);
    } finally {
      setDeletingFiles((prev) => {
        const updated = { ...prev };
        delete updated[fileId];
        return updated;
      });
    }
  };
  

  const handleDownload = async (fileId) => {
    try {
      const response = await fetchFileDownloadUrl({
        campaignId,
        fileId: fileId,
        getAccessTokenSilently,
        getIdTokenClaims,
      });
      
      const { file_url } = response;
      const link = document.createElement('a');
      link.href = file_url;
      link.download = fileId;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };



  useEffect(() => {
    
    const combinedFiles = [...backendFiles, ...droppedFiles].map((file) => (
      
      {
      id: file.id,
      file_name: file.file.name,
      file_url: file.file_url,
      uploaded: file.uploaded,
    }));

    setCreatives(combinedFiles);
  }, [ droppedFiles]);
  
  

  return (
    <div className="dropzone-builder-body">
      <div className='analyze-upload-header-container'>
                        <div className='step-label'>Upload your concepts to test</div>

                    </div>
                        <div className="analyze-dropzone-container" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="analyze-dropzone-content">
                                <img src={upload} className='analyze-upload-image' />
                                <p className="analyze-dropzone-text">
                                    Drag and drop your concepts or click to choose
                                </p>
                                <p className="analyze-dropzone-hint">Max. file size 1 GB</p>
                            </div>
                        </div>

                        <div className="analyze-uploads-container">
                        {loading ? (
                            <div className="loading-indicator"></div>
                                ) : (
                            [...droppedFiles, ...backendFiles].map((fileWrapper) => {
                                const isDroppedFile = droppedFiles.some(
                                    (droppedFile) => droppedFile.id === fileWrapper.id
                                );
                                return (
                                <div key={fileWrapper.id} className="analyze-upload-item">
                                    <span className="analyze-file-name">{fileWrapper.file.name}</span>

                                    {fileWrapper.uploaded ? (
                                        isDroppedFile ? (
                                            <span className="analyze-success-tick">&#10003;</span> // Green tick only for dropped files
                                        ) : null
                                    ) : (
                                        <div className="analyze-progress-bar">
                                            {fileWrapper.uploaded  && isDroppedFile ? (
                                                <span className="analyze-success-tick">&#10003;</span> // Green tick
                                            ) : (
                                                <div
                                                    className="progress-bar-fill"
                                                    style={{ width: `${uploadProgress[fileWrapper.file.name] || 0}%` }}
                                                ></div>
                                            )}
                                        </div>)}

                                    <div className="analyze-actions">
                                        {fileWrapper.uploaded ? (
                                            // Show Download and Delete buttons after upload is complete
                                            <> {!isDroppedFile && (
                                                <button
                                                    className="analyze-download-button"
                                                    onClick={() => handleDownload(fileWrapper.id)}
                                                >
                                                    <FaDownload />
                                                </button>)}
                                                {deletingFiles[fileWrapper.id] ? (
                                                        <div className="delete-loader">
                                                            <ThreeDots color="#7d47fb" height={15} width={30} />
                                                        </div>
                                                    ) : (
                                                        <button
                                                            className="analyze-delete-button"
                                                            onClick={() => handleDelete(fileWrapper.id)}
                                                        >
                                                            <FaTrash />
                                                        </button>
                                                    )}
                                            </>
                                        ) : (
                                            // Show Abort button while upload is in progress
                                            <button
                                                className="analyze-abort-button"
                                                onClick={() => handleAbort(fileWrapper.id)}
                                                disabled={!fileWrapper.abortController}
                                            >
                                                Cancel
                                            </button>
                                        )}
                                    </div>


                                </div>
                            )}
                        
                        ))}
                        </div>
    </div>
  );
  
};

export default Dropzone;
