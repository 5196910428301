export const ProductTourSteps = [
    {
      id: 'create-research-section',
      elementSelector: '.create-research-section',
      title: 'Create Research',
      content: 'You can create AI-moderated interviews from here.',
    },
    {
      id: 'create-tta-project-section',
      elementSelector: '.create-tta-project-section',
      title: 'Transcribe & Analyze',
      content: 'You can transcribe and analyze your pre-recorded customer conversations here.',
    },
    {
      id: 'campaign-list',
      elementSelector: '.campaign-list-container',
      title: 'Your Projects',
      content: 'You can view or edit your past projects here.',
    },
  ];
  