import React, { useState, useMemo } from "react";
import { createTopUpCheckoutSession } from "./ApiUtils";
import "./TopUpModal.css";
import cross from '../assets/cross.svg';
import { useAuth0 } from "@auth0/auth0-react";
import { ThreeDots } from 'react-loader-spinner';
const TopUpModal = ({
  show,
  onClose,
  b2cFee = 6,
  b2bFee = 10,
  b2cIncentiveDefault = 5,
  b2bIncentiveDefault = 10,
}) => {
  const [topUpMethod, setTopUpMethod] = useState("chooseAmount");
  const [quickSelectAmount, setQuickSelectAmount] = useState(null);
  const [customAmount, setCustomAmount] = useState("");
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [b2cChecked, setB2cChecked] = useState(false);
  const [b2bChecked, setB2bChecked] = useState(false);
  const [b2cParticipants, setB2cParticipants] = useState("");
  const [b2bParticipants, setB2bParticipants] = useState("");
  const [b2cIncentive, setB2cIncentive] = useState(b2cIncentiveDefault);
  const [b2bIncentive, setB2bIncentive] = useState(b2bIncentiveDefault);
  const [loading, setLoading] = useState(false); 


  const handleBackdropClick = (e) => {
    if (e.target.classList.contains("modal-backdrop")) {
      onClose();
    }
  };
  

  const calculatedTotal = useMemo(() => {
    if (topUpMethod === "chooseAmount") {
      return quickSelectAmount ? quickSelectAmount : Number(customAmount || 0);
    } else {
      let total = 0;
      if (b2cChecked) {
        const participants = parseInt(b2cParticipants || "0", 10);
        // Add B2C fee + user-input incentive
        total += participants * (b2cFee + (Number(b2cIncentive) || 0));
      }
      if (b2bChecked) {
        const participants = parseInt(b2bParticipants || "0", 10);
        // Add B2B fee + user-input incentive
        total += participants * (b2bFee + (Number(b2bIncentive) || 0));
      }
      return total;
    }
  }, [
    topUpMethod,
    quickSelectAmount,
    customAmount,
    b2cChecked,
    b2bChecked,
    b2cParticipants,
    b2bParticipants,
    b2cIncentive,
    b2bIncentive,
    b2cFee,
    b2bFee
  ]);
  

  const handlePay = async () => {
    try {
      if (calculatedTotal <= 0) {
        alert("Amount must be greater than 0");
        return;
      }
      setLoading(true);
      
      try {
        const checkoutUrl = await createTopUpCheckoutSession({
          amount: calculatedTotal,
          getAccessTokenSilently,
          getIdTokenClaims,
        });
        window.location.href = checkoutUrl;
      } catch (err) {
        console.error("Error creating top-up session:", err);
        alert("Failed to initiate payment. Please try again.");
      } finally {
        setLoading(false); 
      }
    } catch (err) {
      console.error("Error creating top-up session:", err);
      alert("Failed to initiate payment. Please try again.");
    }
  };

  if (!show) return null;

  return (
    <div className="modal-backdrop" onClick={handleBackdropClick}>


      <div className="modal-content">
        <div onClick={onClose} className="close-button">
        <img src={cross} className='cross-icon'/>
        </div>
        <h2 className="modal-title">Add Recruit Balance</h2>
        
        {/* Radio selection: Choose Amount vs Estimate Needed */}
        <div className="content-section">
        <div className="option-container">
          <div
            className={`option-block ${topUpMethod === "chooseAmount" ? "selected" : ""}`}
            onClick={() => setTopUpMethod("chooseAmount")}
          >
            <label className="radio-container">
              <input
                type="radio"
                value="chooseAmount"
                checked={topUpMethod === "chooseAmount"}
                onChange={() => setTopUpMethod("chooseAmount")}
              />
              <span className="custom-radio-circle"></span>
            </label>
            <div className="option-block-label">Choose Amount</div>
          </div>

          <div
            className={`option-block ${topUpMethod === "estimate" ? "selected" : ""}`}
            onClick={() => setTopUpMethod("estimate")}
          >
            <label className="radio-container">
              <input
                type="radio"
                value="estimate"
                checked={topUpMethod === "estimate"}
                onChange={() => setTopUpMethod("estimate")}
              />
              <span className="custom-radio-circle"></span>
            </label>
            <div className="option-block-label">Estimate Needed</div>
          </div>
        </div>
        

        {/* "Choose Amount" method */}
        {topUpMethod === "chooseAmount" && (
          <div className="topup-section">
            <p className="section-label">Select a quick amount or enter your own</p>
            <div className="quick-amounts">
              {[200, 300, 500, 1000].map((amt) => (
                <button
                  key={amt}
                  className={`amount-button ${quickSelectAmount === amt ? "selected" : ""}`}
                  onClick={() => setQuickSelectAmount(amt)}
                >
                  ${amt}
                </button>
              ))}
            </div>
            <div className="input-section">
              <label className="section-label">Other Amount</label>
              <input
                type="number"
                className="input-box"
                placeholder="Eg: 250"
                value={customAmount}
                onChange={(e) => setCustomAmount(e.target.value)}
              />
            </div>
          </div>
        )}

        {/* "Estimate Needed" method */}
        {topUpMethod === "estimate" && (
                <div className="topup-section">
                    {/* ===== B2C checkbox & inputs ===== */}
                    <p className="section-label">Select the type of respondent, specify the number of respondents to recruit, and indicate the incentive amount.</p>
                    <div className="estimate-row">
                    <label className="checkbox-container">
                        <input
                        type="checkbox"
                        checked={b2cChecked}
                        onChange={() => setB2cChecked(!b2cChecked)}
                        />
                        <span className="custom-checkbox"></span>
                        <span className="checkbox-label">B2C</span>
                    </label>
                    {b2cChecked && (
                        <div className="estimate-details">

                        <div className="input-section">
                            <label className="section-label">Incentive</label>
                            <input
                            type="number"
                            className="input-box"
                            value={b2cIncentive}
                            onChange={(e) => setB2cIncentive(e.target.value)}
                            />
                        </div>

                        <div className="input-section">
                            <label className="section-label">No. of Participants</label>
                            <input
                            type="number"
                            className="input-box"
                            value={b2cParticipants}
                            onChange={(e) => setB2cParticipants(e.target.value)}
                            />
                        </div>
                        </div>
                    )}
                    </div>

                    {/* ===== B2B checkbox & inputs ===== */}

                    <div className="estimate-row">
                    <label className="checkbox-container">
                        <input
                        type="checkbox"
                        checked={b2bChecked}
                        onChange={() => setB2bChecked(!b2bChecked)}
                        />
                        <span className="custom-checkbox"></span>
                        <span className="checkbox-label">B2B</span>
                    </label>
                    {b2bChecked && (
                        <div className="estimate-details">

                        <div className="input-section">
                            <label className="section-label">Incentive</label>
                            <input
                            type="number"
                            className="input-box"
                            value={b2bIncentive}
                            onChange={(e) => setB2bIncentive(e.target.value)}
                            />
                        </div>

                        <div className="input-section">
                            <label className="section-label">No. of Participants</label>
                            <input
                            type="number"
                            className="input-box"
                            value={b2bParticipants}
                            onChange={(e) => setB2bParticipants(e.target.value)}
                            />
                        </div>
                        </div>
                    )}
                    </div>
                </div>
                )}

        </div>

            <hr className="full-width-line-topup" />
        <div className="pay-section">
          <div className="amount-to-pay">
          Amount - 
            <span className="section-label-highlight"> ${calculatedTotal} </span>
          </div>
          <button className="pay-button" onClick={handlePay}>
          {loading ? (
                        <ThreeDots color="#fff" height={10} width={24} />
                      ) :(
            "Top up")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopUpModal;
