import React, { useEffect } from 'react';
import Dropzone from '../Dropzone';
import { requestPresignedUrls, deleteFile, fetchFileDownloadUrl } from '../ApiUtils';

const UploadCreativesStep = ({ campaignId, selectedBlock, getAccessTokenSilently, getIdTokenClaims ,
   creatives,setCreatives, validationErrors, setValidationErrors, selectedCreativeQuantity, handleCreativeUpload ,
   clearValidationError
   }) => {
  const clientName = selectedBlock === 3 ? 'concept_testing' : 'default_client';
  

  useEffect(() => {
    if (selectedCreativeQuantity === 'all' && creatives.length > 1) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        uploadCreatives: '',
      }));
    }
  }, [creatives, selectedCreativeQuantity, setValidationErrors]);

  return (
    <div>

    <Dropzone
      campaignId={campaignId}
      clientName={clientName}
      requestPresignedUrls={requestPresignedUrls}
      deleteFile={deleteFile}
      fetchFileDownloadUrl={fetchFileDownloadUrl}
      getAccessTokenSilently={getAccessTokenSilently} 
      getIdTokenClaims={getIdTokenClaims}
      creatives={creatives}
      setCreatives={setCreatives}
      selectedCreativeQuantity={selectedCreativeQuantity}
      handleCreativeUpload={handleCreativeUpload}
      setValidationErrors={setValidationErrors}
      clearValidationError={clearValidationError}

    />
    {validationErrors.uploadCreatives && (
      <div className="err-message">{validationErrors.uploadCreatives}</div>
    )}
    </div>
  );
};

export default UploadCreativesStep;
