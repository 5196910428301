import React, { useState, useRef, useEffect } from 'react';

const ProductTour = ({ steps, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const [maskStyle, setMaskStyle] = useState({});
  const [tooltipPosition, setTooltipPosition] = useState('bottom'); 
  const tooltipRef = useRef();
  const isFullyInViewport = (rect) => {
    return rect.top >= 0 && rect.bottom <= window.innerHeight;
  };
  

useEffect(() => {
  if (currentStep < steps.length) {
    const step = steps[currentStep];
    const element = document.querySelector(step.elementSelector);
    const mainContent = document.querySelector('.main-content');

    if (element && mainContent) {
        const tooltipHeight = tooltipRef.current?.offsetHeight || 0; 
      // Element rect relative to the viewport
      const elementRect = element.getBoundingClientRect();
      // Container rect relative to the viewport
      const containerRect = mainContent.getBoundingClientRect();

      // The element’s “top” inside .main-content = 
      //   (element’s top in viewport) - (container’s top in viewport)
      const offsetTop = elementRect.top - containerRect.top + mainContent.scrollTop;
      const offsetLeft = elementRect.left - containerRect.left + mainContent.scrollLeft +40;
      

      // Because highlight-mask is `position: absolute` in .main-content:
      setMaskStyle({
        top: offsetTop + 'px',
        left: offsetLeft + 'px',
        width: elementRect.width + 'px',
        height: elementRect.height + 'px'
      });

      // For the tooltip, do the same offset logic
      // Decide above/below based on visibility or preference
      if (!isFullyInViewport(elementRect)) {
        // place tooltip above
        setTooltipPosition('top');
        setTooltipStyle({
          top: offsetTop - tooltipHeight - 10 + 'px',
          left: offsetLeft + elementRect.width / 2 + 'px',
          transform: 'translateX(-50%)'
        });
      } else {
        // place tooltip below
        setTooltipPosition('bottom');
        setTooltipStyle({
          top: offsetTop + elementRect.height + 10 + 'px',
          left: offsetLeft + elementRect.width / 2 + 'px',
          transform: 'translateX(-50%)'
        });
      }
    }
  }
}, [currentStep, steps]);


  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onClose();
    }
  };

  if (currentStep >= steps.length) return null;

  return (
    <div className="product-tour">
      {/* Mask */}
      <div className="mask" />
      <div className="highlight-mask" style={maskStyle} />

      {/* Tooltip */}
      <div
        ref={tooltipRef}
        className={`custom-tooltip ${tooltipPosition}`}
        style={tooltipStyle}
      >
        <div className="tooltip-header">{steps[currentStep].title}</div>
        <div className="tooltip-content">{steps[currentStep].content}</div>
        
        <div className="tooltip-footer">
        <div className="product-tour-progress-bar ">
          {steps.map((_, index) => (
            <span
              key={index}
              className={`progress-dot ${index === currentStep ? 'active' : ''}`}
            />
          ))}
        </div>

        <button onClick={nextStep} className="tooltip-next-btn">
          {currentStep === steps.length - 1 ? 'Finish' : 'Next'}
        </button>
        </div>

        <button className="tooltip-close-btn" onClick={onClose}>
          &times;
        </button>
      </div>
    </div>
  );
};

export default ProductTour;